import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import { ReactComponent as IconClose } from '../../assets/images/svg/close.svg';
import styles from './Sidenav.module.scss';

const cn = classNames.bind(styles);

class Sidenav extends Component {
  static propTypes = {
    children: Type.oneOfType([Type.arrayOf(Type.node), Type.node]),
    position: Type.oneOf(['left', 'right']),
    isOpen: Type.bool,
    showCloseButton: Type.bool,
    backgroundColor: Type.string,
    onClose: Type.func,
    closeOnOverlayClick: Type.bool
  };

  static defaultProps = {
    backgroundColor: '#000',
    position: 'left',
  };

  handleOverlayClick = () => {
    if (this.props.closeOnOverlayClick) {
      this.props.onClose()
    }
  }
  render() {
    const { backgroundColor, position, children, showCloseButton, isOpen, onClose } = this.props;

    return (
      <div
        className={cn({
          root: true,
          opened: isOpen,
        })}
      >
        <div
          onClick={this.handleOverlayClick}
          className={cn({
            backdrop: true,
          })}
        >
        </div>

        <div
          className={cn({
            sidenav: true,
            [position]: true,
          })}
          style={{
            backgroundColor
          }}
        >
          {showCloseButton &&
            <span className={styles.close} onClick={onClose}>
              <IconClose />
            </span>
          }

          <div className={styles.wrapper}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default Sidenav;
