import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { CartProduct } from '../../components';
import styles from './Cart.module.scss';
import TShirtFooter from './TShirtFooter';
import TourneyFooter from './TourneyFooter';
import TShirtForm from './TShirtForm';
import TourneyForm from './TourneyForm';
import FormControl from './FormControl';
import { validateURL } from '../../utils/validations';

class Cart extends Component {

  state = {
    productErrors: {}
  }

  handleValidateProducts = () => {
    const errors = {};

    Object.values(this.props.items)
      .forEach(product => {
        if (product.type === 1) {
          errors['1'] = !product.size ? 'Укажите размер футболки' : false
        } 
        if (product.type === 4) {
          errors['4'] = !product.date || !product.time ? 'Укажите дату и время поездки' : false
        } 
        if (product.type === 2) {
          errors['2'] = !validateURL(product.replay) ? 'Укажите правильную ссылку' : false
        } 
      });
    this.setState({ productErrors: errors });
    return Object.values(errors).filter(n => !!n).length === 0
  }

  handleReviewInput = event => {
    console.log(event, event.target)
    const payload = {
      id: Number(event.target.getAttribute('data-id')),
      replay: event.target.value
    }
    this.props.onItemUpdate(payload);
  }

  handleInput = (event) => {
    const {name, value} = event.currentTarget;
    this.props.handleFormInput({ name, value })
  }

  handleSumbit = () => {
    const isValid = this.handleValidateProducts();
    if (isValid) {
      this.props.handleOrder()
      window.dataLayer && window.dataLayer.push({
        "event": "uEvent",
        "eventCategory": "Shop",
        "eventAction": "MakeOrder"
      });
    }
  }

  renderForms = () => {
    const { products, productTypes, userData } = this.props;
    console.log(products, 'products')
    return  products.map(product => {
      const currentType = productTypes[product.type];

      if (currentType === 't-shirt') return (
        <div className={styles.form} key={`cart-form-${product.id}`}>
          <div className={styles.formTitle}>Доставка футболки</div>
          <TShirtForm
            userData={userData}
            handleChange={this.handleInput}
            labelClass={styles.formLabel}
            errors={this.props.errors}
            rowClass={styles.formRow} />
          <div className={styles.formText}>После совершения покупки мы&nbsp;можем связаться с&nbsp;тобой по&nbsp;e-mail для уточнения деталей.</div>
        </div>
      );

      if (currentType === 'tourney') return (
        <div className={styles.form} key={`cart-form-${product.id}`}>
          <div className={styles.formTitle}>Поездка в буткэмп Team Empire</div>
          <TourneyForm
            userData={userData}
            handleChange={this.handleInput}
            errors={this.props.errors}
            labelClass={styles.formLabel}
            rowClass={styles.formRow} />
          <div className={styles.formText}>После совершения покупки на&nbsp;твой e&nbsp;&mdash; mail придет письмо с&nbsp;инструкцией по&nbsp;дальнейшим шагам и&nbsp;уточнением всей необходимой информации для поездки в&nbsp;буткэмп Team Empire.</div>
        </div>
      );

      if (currentType === 'winstrike') return (
        <div className={styles.form} key={`cart-form-${product.id}`}>
          <div className={styles.formTitle}>Билет в корнер Winstrike</div>
          <div className={styles.formText}>После совершения покупки в&nbsp;личном кабинете появится купон с&nbsp;уникальным кодом, который необходимо предъявить в&nbsp;любом корнере Winstrike и&nbsp;обменять на&nbsp;игровые часы.</div>
        </div>
      );

      if (currentType === 'blast') return (
        <div className={styles.form} key={`cart-form-${product.id}`}>
          <div className={styles.formTitle}>{product.name}</div>
          <div className={styles.formText}>После покупки вы получите промокод (в личном кабинете OMEN2PLAY). Чтобы получить значок, покажите промокод администратору Winstrike Arena в ЦДМ на Лубянке: <a href="https://arena.winstrike.gg/" target="_blank" rel="noopener noreferrer">https://arena.winstrike.gg/</a><br />Если промокод корректный, администратор передаст вам значок.</div>
        </div>
      );

      if (currentType === 'review') return (
        <div className={styles.form} key={`cart-form-${product.id}`}>
          <div className={styles.formTitle}>Разбор игры от профи</div>
          <label className={styles.formLabel}>Email на который мы пришлем разбор твоей игры<span>*</span></label>
          <FormControl error={this.props.errors && this.props.errors.email}>
            <input type="email" name="email" value={userData.email} onChange={this.handleInput} />
          </FormControl>
        </div>
      );
      return null;
    });
  }

  renderCartFooter = (product) => {
    const {
      productTypes,
      items,
      onItemUpdate,} = this.props;
    const currentType = productTypes[product.type];

    if (currentType === 't-shirt') {
      return (
        <TShirtFooter
          amount={items[product.id].quantity}
          updateHandler={onItemUpdate}
          maxAmount={product.stock}
          sizes={product.sizes}
          size={items[product.id].size}
          productId={product.id} />
      )
    }
    if (currentType === 'review') {
      return (<div className={styles.replayFooter}>
        <div className={styles.replayLabel}>Ссылка на replay игры </div>
        <input
          type="text"
          value={items[product.id].replay}
          data-id={product.id}
          onChange={this.handleReviewInput}
          onBlur={this.reviewValidate}
          className={styles.replayInput} />
      </div>)
    }

    if (currentType === 'tourney') {
      return (<div>
        <TourneyFooter
          dates={product.bootcamp_dates}
          productId={product.id}
          selectedDate={items[product.id].date}
          selectedTime={items[product.id].time}
          updateHandler={onItemUpdate}
        />
      </div>)
    }
    return null
  }

  renderCards = () => {
    const { products, onItemRemove} = this.props;
    return products.map(product => (
      <div className={styles.listItem} key={product.id}>
        <CartProduct
          error={this.state.productErrors[product.type]}
          id={product.id}
          type={product.type}
          name={product.name}
          image={product.image}
          text={product.description}
          price={product.price}
          isDisabled={product.amount === 0}
          onRemove={onItemRemove}>
          {this.renderCartFooter(product)}
        </CartProduct>
      </div>
    ));
  }
  
  render() {
    const { products, total, userCash } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <div className={styles.account}>
            <div className={styles.accountLabel}>на счету</div>
            <div className={styles.accountSumm}>{userCash}&nbsp;<span title="Omen Loyalty Points">OLP</span></div>
          </div>
          <div className={styles.header}>
            <div className={styles.title}>Корзина</div>
          </div>
          <div className={styles.body}>
            <Scrollbars
              style={{ width: '100%', height: '100%' }}
              hideTracksWhenNotNeeded
              autoHide
              renderTrackHorizontal={props => <div {...props} className={styles.trackHorizontal} />}
              renderTrackVertical={props => <div {...props} className={styles.trackVertical} />}>

              <div className={styles.spacer}></div>
              <div className={styles.cardsList}>{ products.length ? this.renderCards() : 'Пусто'}</div>
              {products.length ? (
                <div className={styles.formWrapper}>
                  <div className={styles.approveTitle}>Подтверждение заказа</div>
                  {this.renderForms()}
                </div>
              ) : null}
              <div className={styles.spacer}></div>
            </Scrollbars>
          </div>
          { total ?
            (
              <div className={styles.footer}>
                <div className={styles.total}>
                  <div className={styles.totalInner}>
                    <div className={styles.totalLabel}>Итого:</div>
                    <div className={styles.totalSumm}>{total} <span title="Omen loyalty Points">olp</span></div>
                  </div>
                </div>
                <div className={styles.cartBtnWrapper}>
                  {userCash < total ? <div className={styles.footerWarn}>Недостаточно {total - userCash} OLP</div> : <button onClick={this.handleSumbit} className={styles.cartBtn}>Купить <span></span></button>}
                </div>
              </div>
            )
            : null }
        </div>
      </div>
    );
  }
}

export default Cart;
