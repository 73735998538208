import React, { Component } from 'react';
import 'react-app-polyfill/ie11';
import 'babel-polyfill'; 
import { Route, Redirect } from "react-router-dom";
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { Home, SignIn, SignUp, Profile, Mechanics, MobileMechanics, Game,
  Shop, Account, CSGO, DOTA2 } from './containers';
import { AuthProvider, DeviceProvider, ModalProvider, Header, MobileHeader,
  Footer, Sidenav, MobileMenu, SwitchDeviceModal, Unsubscribe, Cart, BrandModal } from './components';
import { About } from './api';
import detectMobileBrowser from './utils/detectMobileBrowser';
import { steamUrlToOpenId } from "./utils/steamUrlToOpenId";
import { Auth } from './api';
import shopMethods from './containers/Shop/Methods';
import './App.scss';
import { productTypes } from './containers/Shop/ProductTypes';

export const AuthContext = React.createContext(false);
export const DeviceContext = React.createContext(false);
export const ModalContext = React.createContext(false);
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isOpenMobileMenu: false,
      isOpenModal: false,
      needRegistration: false,
      steam_id: null,
      player_id: null,
      isAuthorized: this.checkAuth(),
      playerId: this.getPlayerId(),
      isHomePage: true,
      isMobile: false,
      footerData: {},
      error: null,
      appError: null,
      isCartOpen: false,
      products: [],
      cartProducts: {},
      userCash: 0,
      cartForm: {
        fullname: "",
        delivery_address: "",
        phone: "",
        comment: "",
        email: ""
      },
      cartFormErrors: {},
      isOrderModal: false,
      isSignOutModal: false,
      isSuccessCheckModal: false,
      orderError: null,
      user: null,
      isNoticeModal: false,
      openIdParams: null,
      isOpenInform: false,
    };
  }

  handleCloseInform = () => {
    this.setState({ isOpenInform: false })
  }

  selectedProducts = () => {
    const { cartProducts, products } = this.state;
    const inCartIds = Object.keys(cartProducts).map(n => Number(n));
    return products.filter(product => inCartIds.includes(product.id))
  }

  handleOpen = () => {
    this.setState({ isOpen: true, isCartOpen: false, });
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  handleOpenCart = () => {
    //this.state.isAuthorizedgit 
    if (this.state.isAuthorized) {
      this.setState({ isCartOpen: true, isOpen: false });
    } else {
      this.setState({ isCartOpen: false, isOpen: true });
    }
  }

  handleSignOut = () => {
    try {
      localStorage.removeItem('omencart');
      localStorage.removeItem('token_access');
      localStorage.removeItem('player_id');
    } catch(err) {console.log('err', err)}
    
    this.setState({
      user: null,
      isAuthorized: false,
      playerId: null,
      steam_id: null,
      player_id: null,
    });

    window.location.href = '/'
  }
  
  handleCloseCart = () => {
    this.setState({ isCartOpen: false });
  }

  handleCloseSignOut = () => {
    this.setState({ isSignOutModal: false });
  }

  hadleCloseAppError = () => {
    this.setState({
      isErrorModal: false,
      appError: null
    });
  }

  hadleShowAppError = ({title = 'Ошибка', text = ''}) => {
    this.setState({
      isErrorModal: true,
      appError: {
        title,
        text
      }
    });
  }

  handleOpenSignOut = () => {
    this.setState({ isSignOutModal: true });
  }

  handleOpenMobileMenu = () => {
    this.setState({ isOpenMobileMenu: true });
  }

  handleCloseMobileMenu = () => {
    this.setState({ isOpenMobileMenu: false });
  }

  handleOpenModal = () => {
    this.setState({ isOpenModal: true });
  }

  handleCloseModal = () => {
    this.setState({ isOpenModal: false });
  }
  
  handleStatsUserId = () => {
    const pid = this.getPlayerId();
    console.log('pid', pid)
    if (pid && window.dataLayer) {
      console.log('pid', window.dataLayer)
      window.dataLayer.push({ opid: pid });
    }
  }

  handleCloseOrderModal = () => {
    this.setState({ isOrderModal: false });
  }

  handleAddToCart = shopMethods.addToCart.bind(this)
  getCartCount = shopMethods.getCartCount.bind(this)
  getCartList = shopMethods.getCartList.bind(this)
  restoreCart = shopMethods.restoreCartFromLocal.bind(this)
  dropCard = shopMethods.dropCard.bind(this)
  handleRemoveFromCart = shopMethods.removeFromCart.bind(this)
  handleUpdateCartItem = shopMethods.updateCartItem.bind(this)
  getTotalPrice = shopMethods.getTotalPrice.bind(this)
  getProducts = shopMethods.getProducts.bind(this)
  handleOrder = shopMethods.submitFormHandler.bind(this)
  handleCartFormInput = shopMethods.handleCartFormInput.bind(this)
  getUserInfo = shopMethods.getUserInfo.bind(this)

  handleStatsUserId = () => {
    const pid = this.getPlayerId();
    if (pid && window.dataLayer) {
      window.dataLayer.push({ opid: pid });
    }
  }

  getPlayerId() {
    return localStorage.getItem('player_id');
  }

  checkAuth = () => {
    return !!localStorage.getItem('token_access');
  }

  handleSignUp = (payload) => {
    payload.id_steam = this.state.steam_id
    const sendingDate = Object.assign({}, payload, {params: this.state.openIdParams })
    Auth.signUp(sendingDate)
      .then(({ data }) => {
        localStorage.setItem('token_access', data.token_access);
        localStorage.setItem('player_id', data.player_id);
        this.setState({
          isAuthorized: true,
          isOpen: false,
          needRegistration: false,
          playerId: data.player_id
        });

        this.handleStatsUserId()
      })
      .catch((error) => {
        this.hadleShowAppError({
          title: 'Ошибка авторизации',
          text: 'Что-то пошло не так. Попробуйте позже'
        });
      })
  }

  toggleSuccesCheckModal = (flag) => {
    this.setState({
      isSuccessCheckModal: flag
    })
  }

  handleSignIn = async (steamId) => {
    try {
      this.setState({ steam_id: steamId });
      const isExist = await Auth.isUserExist(steamId);
      if (isExist) {
        const { data } = await Auth.signIn({
          id_steam: steamId,
          params: this.state.openIdParams
        });
        localStorage.setItem('token_access', data.token_access);
        localStorage.setItem('player_id', data.player_id);

        if (data.token_access) {
          this.setState({ isAuthorized: true });
        }
        this.checkAuth();
        this.handleStatsUserId();
      }
    } catch (error ) {
      if (error.response && error.response.status === 404) {
        this.setState({ needRegistration: true });
      }
    }
  }

  async componentDidMount() {
    const isMobile = detectMobileBrowser();
    const query = queryString.parse(this.props.location.search);

    if (query && query['openid.sig']) {
      this.setState({ openIdParams: query })
    }
    //isOpenInform
    this.restoreCart();
    this.setState({ isMobile: isMobile});
    if (isMobile) {
      document.body.classList.add('isMobile');
    }

    
    try {
      const { isAuthorized } = this.state;
      const steamId = steamUrlToOpenId(this.props.location.search);

      if (steamId && !isAuthorized) {
        this.handleSignIn(steamId)
      } else {
        this.handleStatsUserId()
      }

      const footerData = await About.getInfo();
      this.setState({ footerData: footerData.data });

    } catch (error) {
      this.setState({ error })
    }
  }

  componentDidUpdate() {
    const { isOpen, isOpenMobileMenu, isOpenModal, error } = this.state;

    // if (error){
    //   throw error
    // }

    if (isOpen || isOpenMobileMenu || isOpenModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }

  onSetHomePage = (newState) => {
    this.setState({ isHomePage: newState });
  };

  renderMobileMenu() {
    const { isMobile, isOpenMobileMenu, isHomePage } = this.state;

    if (!isMobile) {
      return null
    } else {
      return (
        <MobileMenu
          isOpen={isOpenMobileMenu}
          openSignIn={this.handleOpen}
          onSignOut={this.handleOpenSignOut}
          isHomePage={isHomePage}
          onClose={this.handleCloseMobileMenu}
        />
      );
    }
  }

  renderInfoModalText() {
    return <>
      <p>OMEN by HP — глобальный партнер турнира <br /><a href="https://blastproseries.com/moscow-ru/">BLAST Pro Series</a>, который пройдет в Москве уже 14 сентября!<br /></p><p>В <a href="https://omen2play.ru/shop">магазине платформы</a> появились билеты — обменивай свои OLP на шанс посетить международный турнир по любимой игре! Покупай любой игровой ноутбук в <a href="https://www.mvideo.ru/noutbuki-planshety-komputery-8/noutbuki-118/f/category=igrovye-noutbuki-3607" target="_blank">М.Видео</a> или <a href="https://www.citilink.ru/catalog/mobile/notebooks/" target="_blank">Ситилинк</a> от HP, загрузи чек от покупки в личный кабинет до 13 сентября (20:00) и получи сразу 3500 OLP, которых хватит на билет.</p><p>Торопись, количество товаров ограничено!</p><p><a href="https://omen2play.ru/mechanics">Подробнее о платформе</a>.</p>
    </>
  }

  renderHeader() {
    const { isMobile, isHomePage } = this.state;

    if (isMobile) {
      return (
        <MobileHeader
          onOpen={this.handleOpenMobileMenu}
        />
      );
    } else {
      return (
        <Header
          isHomePage={isHomePage}
          location={this.props.location}
          onOpen={this.handleOpen}
          onSignOut={this.handleOpenSignOut}
        />
      );
    }
  }

  render() {
    const { isOpen, footerData, needRegistration, isAuthorized, playerId,
      isMobile, isOpenModal, isCartOpen, orderError } = this.state;
    const selectedProducts = this.selectedProducts();
    const cartCount = this.getCartCount();
    const total = this.getTotalPrice();

    return (
      <AuthProvider value={{
        isAuthorized: isAuthorized,
        handleOpen: this.handleOpen
      }}>
        <DeviceProvider value={{
          isMobile
        }}>
          <ModalProvider value={{
            isOpenModal,
            onShowAppError: this.hadleShowAppError,
            onOpenModal: this.handleOpenModal,
            onCloseModal: this.handleCloseModal,
            toggleSuccesCheckModal: this.toggleSuccesCheckModal
        }}>
            { this.renderHeader() }
            <div className="page-content">
              <Route
                path="/"
                exact
                render={(props) => (
                  <Home
                    {...props}
                    playerId={playerId}
                    onSetHomePage={this.onSetHomePage}
                  />
                )}
              />
              {!isMobile &&
                <Route
                  path="/teams"
                  render={(props) => (
                    isAuthorized ? (
                      <Profile {...props} onSetHomePage={this.onSetHomePage} />
                    ) : (
                      <Redirect to="/" />
                    )
                  )}
                />
              }
              <Route
                path="/mechanics"
                render={(props) => {
                  if (!isMobile) {
                    return (
                      <Mechanics {...props} onSetHomePage={this.onSetHomePage} />
                    )
                  } else {
                    return <MobileMechanics {...props} onSetHomePage={this.onSetHomePage} />
                  }
                }}
              />
              {!isMobile &&
                <Route
                  path="/game"
                  render={(props) => (
                    <Game {...props} onSetHomePage={this.onSetHomePage} />
                  )}
                />
              }
              <Route
                path="/account"
                render={(props) => (
                  isAuthorized ? (
                    <Account
                      {...props}
                      getUserInfo={this.getUserInfo}
                      signOut={this.handleOpenSignOut}
                      user={this.state.user}
                      onSetHomePage={this.onSetHomePage} />
                  ) : (
                      <Redirect to="/" />
                    )
                )}
              />
              <Route
                path="/shop"
                exact
                render={(props) => (
                  <Shop
                    {...props}
                    products={this.state.products}
                    onGetProducts={this.getProducts}
                    onOpenCart={this.handleOpenCart}
                    onCloseCart={this.handleCloseCart}
                    onSetHomePage={this.onSetHomePage}
                    onAddtoCart={this.handleAddToCart}
                    cartCount={cartCount}
                    getUserInfo={this.getUserInfo}
                    cartProducts={this.state.cartProducts}
                    selectedProducts={selectedProducts.map(n => n.id)}
                  />
                )}
              />
              <Route
                path="/csgo"
                exact
                render={(props) => (
                  <CSGO {...props}
                    playerId={playerId}
                    openLogin={this.handleOpen}
                    products={this.state.products}
                    onGetProducts={this.getProducts}
                    onSetHomePage={this.onSetHomePage} />
                )}
              />

              <Route
                path="/dota2"
                exact
                render={(props) => (
                  <DOTA2 {...props}
                    playerId={playerId}
                    openLogin={this.handleOpen}
                    products={this.state.products}
                    onGetProducts={this.getProducts}
                    onSetHomePage={this.onSetHomePage} />
                )}
              />
            </div>

            <Route
              path="/notification/unsubscribe"
              render={(props) => (
                <Unsubscribe {...props} onSetHomePage={this.onSetHomePage} />
              )}
            />

            <Sidenav
              isOpen={isOpen || needRegistration}
              onClose={this.handleClose}
              showCloseButton
            >
              {
                needRegistration
                  ? <SignUp location={this.props.location} handleSignUp={(payload) => this.handleSignUp(payload)}/>
                : <SignIn location={this.props.location} />
              }

            </Sidenav>
            <Sidenav
              isOpen={isCartOpen}
              onClose={this.handleCloseCart}
              closeOnOverlayClick
              showCloseButton
            >
              <Cart
                items={this.state.cartProducts}
                products={selectedProducts}
                productTypes={productTypes}
                total={total}
                userData={this.state.cartForm}
                userCash={(this.state.user && this.state.user.points_OLP) || 0}
                handleOrder={this.handleOrder}
                errors={this.state.cartFormErrors}
                handleFormInput={this.handleCartFormInput}
                onItemUpdate={this.handleUpdateCartItem}
                onItemRemove={this.handleRemoveFromCart}/>
            </Sidenav>
            <Footer data={footerData} />

            {this.renderMobileMenu()}

            <SwitchDeviceModal
              isOpen={isOpenModal}
              onClose={this.handleCloseModal}
            />

            <BrandModal
              isOpen={this.state.isOrderModal}
              title={orderError ? 'Ошибка' : 'Готово'}
              onClose={this.handleCloseOrderModal}
              leftControl={(name) => (
                <a href="/account" className={name}>В личный кабинет</a>
              )}
              rightControl={(name) => (
                <button onClick={this.handleCloseOrderModal} className={name}>Ок</button>
              )}
            >
              <div>{orderError ? orderError : <div>Поздравляем!<br />Покупка совершена!</div>}</div>
            </BrandModal>

            <BrandModal
              isOpen={this.state.isSignOutModal}
              title="Выйти из профиля?"
              onClose={this.handleCloseSignOut}
              leftControl={(name) => (
                <button onClick={this.handleCloseSignOut} className={name}>остаться</button>
              )} 
              rightControl={(name) => (
                <button onClick={this.handleSignOut} className={name}>Выйти</button>
              )}
            >
              <div>Возвращайся к нам снова.</div>
            </BrandModal>

            <BrandModal
              isOpen={this.state.isErrorModal}
              title={this.state.appError && this.state.appError.title}
              onClose={this.hadleCloseAppError}
              rightControl={(name) => (
                <button onClick={this.hadleCloseAppError} className={name}>Закрыть</button>
              )}
            >
              <div>{this.state.appError && this.state.appError.text}</div>
            </BrandModal>

            <BrandModal
              wideContent
              isOpen={this.state.isSuccessCheckModal}
              title="Чек в обработке"
              onClose={() => this.toggleSuccesCheckModal(false)}
              rightControl={(name) => (
                <button onClick={() => this.toggleSuccesCheckModal(false)} className={name}>Закрыть</button>
              )}
              leftControl={(name) => (
                <a href="/account" className={name}>В личный кабинет</a>
              )}
            >
              <div>Загруженый чек поступил в обработку. Вы можете следить за его статусом в личном кабинете</div>
            </BrandModal>
            
            <BrandModal
              isOpen={this.state.isOpenInform}
              title="Билеты на турнир BLAST Pro Series"
              onClose={this.handleCloseInform}
              wideContent
              leftControl={(name) => (
                <a href="/shop" className={name}>В магазин</a>
              )}
              rightControl={(name) => (
                <button onClick={this.handleCloseInform} className={name}>Закрыть</button>
              )}
            >
              {this.renderInfoModalText()}
            </BrandModal>
          </ModalProvider>
        </DeviceProvider>
      </AuthProvider>
    );
  }
}
export default withRouter(App);
