import { request } from './instance';
import { dailyEvents } from './mockedAPI';
const Profile = {
  info() {
    return request({
      url: 'player_profile/',
      canApplyToken: true,
    });
  },
  edit(data) {
    return request({
      url: 'edit_player_profile/',
      method: 'POST',
      canApplyToken: true,
      data,
    })
  },
  getDailyEvents() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dailyEvents)
      }, 1000)
    })
  },
  getOrders(params) {
    return request({
      url: 'player_profile/orders',
      method: 'GET',
      canApplyToken: true,
      params
    })
  },
  getOLPHistory(params) {
    return request({
      url: 'player_profile/olp_history',
      method: 'GET',
      canApplyToken: true,
      params
    })
  }
};

export default Profile;
