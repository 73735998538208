import React from 'react';
import { ReactComponent as IconArrow } from '../../assets/images/svg/arrow.svg';
import { ReactComponent as IconArrow2 } from '../../assets/images/svg/arrow-2.svg';
import { ReactComponent as Frame } from './frame.svg';
import { DeviceContext } from "../../App";

import styles from './ProductCarousel.module.scss';
import ScrollOffsetBox from "../ScrollOffsetBox/ScrollOffsetBox";

function ProductItem(item, idx, length) {
  idx = Math.min(idx, length - 4);

  return (
    <DeviceContext.Consumer key={item.id}>
      {({ isMobile }) => (
        <ScrollOffsetBox offset={100} initialOffset={idx * 100 - 100} disable={isMobile}>
          <div className={styles.item}>
            <Frame className={styles.frame}/>

            <div className={styles.card}>
              <IconArrow2 className={styles.next}/>
              <div className={styles.cover}>
                <div
                  className={styles.image}
                  style={{ backgroundImage: `url("${item.image}")` }}
                />
              </div>
              <div className={styles.body}>
                <div className={styles.container}>
                  <div className={styles.content}>
                    <span className={styles.description}>
                      {item.description}
                    </span>
                    <span className={styles.title}>
                      {item.name}
                    </span>
                  </div>
                  <a
                    href={item.url_hp}
                    className={styles.link}
                    target="_blank"
                    rel='noreferrer noopener'
                  >
                    <IconArrow className={styles.icon}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ScrollOffsetBox>
      )}
    </DeviceContext.Consumer>
  );
}

export default ProductItem;
