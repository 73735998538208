import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import { ReactComponent as IconNext } from './arrows.svg';
import styles from './MenuAbilities.module.scss';

const cn = classNames.bind(styles);

class MenuAbilities extends Component {
  static propTypes = {
    items: Type.arrayOf(Type.shape({
      icon: Type.object,
      label: Type.string,
    })),
    currentItem: Type.number.isRequired,
    onClick: Type.func.isRequired,
  };

  handleNextClick = () => {
    const { items, currentItem, onClick } = this.props;
    const nextItem = currentItem >= items.length - 1 ? 0 : currentItem + 1;

    if (onClick) {
      onClick(nextItem);
    }
  }

  handleItemClick = ({ currentTarget }) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(Number(currentTarget.id));
    }
  }

  render() {
    const { items, currentItem } = this.props;

    return (
      <div className={styles.menu}>
        <div className={styles.wrapper}>
          {items.map((item, idx) => {
            return (
              <span
                key={idx}
                id={idx}
                onClick={this.handleItemClick}
                className={cn({
                  item: true,
                  active: idx === currentItem
                })}
              >
                <item.icon className={cn({
                  icon: true,
                  [`icon${idx+1}`]: true,
                })}/>
                <span className={styles.label}>
                  {item.label}
                </span>
              </span>
            )
          })}
        </div>
        <span
          className={styles.button}
          onClick={this.handleNextClick}
        >
          <IconNext className={styles.buttonIcon} />
        </span>
      </div>
    );
  }
}

export default MenuAbilities;
