export default [
  {
    name: 'vk',
    label: 'vk',
  },
  {
    name: 'fb',
    label: 'f',
  },
];
