import { Shop as ShopApi } from '../../api';
import { productTypes } from './ProductTypes';
import { validateEmail, errorMessages } from '../../utils/validations';

function saveTolocal(data) {
  localStorage.setItem('omencart', JSON.stringify(data))
}

function parseOrderErrors (error) {
  if (!error) return ['Неизвестная ошибка'];
  let arr = [];
  Object.values(error).forEach(n => {
    if (Array.isArray(n)) {
      arr = [...arr, ...n]
    }
    else if (typeof n === 'string') {
      arr = [...arr, n]
    }
  });
  return arr
}

function validateCartForm (cartProducts, cartForm) {
  const requiredFields = [];
  const errors = {};

  Object.values(cartProducts).forEach(p => {
    if (productTypes[p.type] === 't-shirt') {
      requiredFields.push('fullname', 'delivery_address')
    }
    if (productTypes[p.type] === 'tourney') {
      requiredFields.push('phone')
    }
    if (productTypes[p.type] === 'review') {
      requiredFields.push('email')
    }
  });

  requiredFields.forEach(fieldName => {
    if (!cartForm[fieldName]) {
      errors[fieldName] = errorMessages.required
    } else {
      errors[fieldName] = false
    }
    if (fieldName === 'email') {
      const isValid = validateEmail(cartForm[fieldName]);

      if (!isValid) {
        errors[fieldName] = errorMessages.email
      } else {
        errors[fieldName] = false
      }
    }
  });

  return errors
};

export default {
  /**
   * Добавляет товар в корзину по айдишнику
   */
  addToCart (productId) {
    const product = this.state.products.find(n => n.id === productId);
    if (!product) return;
    this.setState(prevState => {
      const newItem = {};
      newItem[productId] = { quantity: 1, id: productId, type: product.type };
      const updatedCart = Object.assign({}, prevState.cartProducts, newItem);
      saveTolocal(updatedCart);
      return { cartProducts: updatedCart }
    });
  },
  handleCartFormInput({name, value}) {
    this.setState(prevState => {
      const newCart = Object.assign({}, prevState.cartForm);
      newCart[name] = value
      return { cartForm: newCart}
    })
  },
  dropCard() {
    localStorage.removeItem('omencart');
    this.setState({
      cartProducts: {},
      cartFormErrors: {},
      orderError: null,
    })
  },
  updateCartItem(data) {
    this.setState(prevState => {
      const newItem = {};
      newItem[data.id] = Object.assign({}, prevState.cartProducts[data.id], data);
      const updatedCart = Object.assign({}, prevState.cartProducts, newItem);
      saveTolocal(updatedCart);
      return { cartProducts: updatedCart }
    })
  },
  removeFromCart(productId) {
    const products = Object.assign({}, this.state.cartProducts);
    delete products[productId];
    saveTolocal(products);
    this.setState({ cartProducts: products });

    if (Object.keys(products).length === 0) {
      this.handleCloseCart()
    }
  },
  /**
   * Возвращает количество товаров в корзине
   */
  getCartCount() {
    return Object.values(this.state.cartProducts).reduce((acc, val, idx) => {
      if (val && val.quantity) {
        return acc += val.quantity
      }
      return acc
    }, 0)
  },
  getCartList() {
    return Object.values(this.state.cartProducts).map(productInCart => {
     
      const product = this.state.products.find(prod => prod.id === productInCart.id)
      return Object.assign({}, productInCart, product)
    })
  },
  /**
   * Восстанавлиеваем корзину из ЛС
   */
  restoreCartFromLocal() {
    const cart = localStorage.getItem('omencart');
    if (!cart) return;
    try {
      const data = JSON.parse(cart);
      if (data) {
        this.setState({ cartProducts: data})
      }
    } catch(e) {
      console.log(e)
    }
  },
  getTotalPrice() {
    let result = 0;
    if (!this.state.products.length) return result;
    return Object.values(this.state.cartProducts).reduce((acc, val)=> {
      const product = this.state.products.find(n => n.id === val.id );
      if (product) return result += product.price * val.quantity
      return result
    }, result)
  },
  async getProducts() {
    try {
      const products = await ShopApi.getProducts();
      if (products && products.data && products.data.results) {
        this.setState({ products: products.data.results })
      }
    }catch(error) {
      console.log(error)
    }
  },

  async getUserInfo() {
    const user = await ShopApi.getProfile();
    if (user) {
      this.setState({ user: user.data })
      return user.data
    }
    return null
  },

  handleUserInfo(data) {
    this.setState({ user: data })
  },

  submitFormHandler() {
    const { cartProducts, cartForm } = this.state;

    const errors = validateCartForm(cartProducts, cartForm);
    this.setState({ cartFormErrors: errors });
    if (Object.values(errors).some(item => Boolean(item))) return;
    
    const order = { ...cartForm };

    // Удаляю пустые ключи
    Object.keys(order).forEach(n => {
      if (order[n] === '') delete order[n]
    });

    // Удаляю поле type перед отправкой
    order.products = Object.values(cartProducts).map(({ type, ...product }) => product);

    ShopApi.postOrder(order).then(() => {
      this.setState({
        isOrderModal: true,
        orderError: false,
        isCartOpen: false,
        cartProducts: {},
      });
      this.dropCard();
      this.getUserInfo();
    }).catch(err => {
      this.setState({
        isOrderModal: true,
        orderError: parseOrderErrors(err.response.data),
      });
    })
  } 
}