import React, { Component } from 'react';
import Type from 'prop-types'
import styles from './ShopCard.module.scss';
import classNames from 'classnames/bind';
const cn = classNames.bind(styles);

class ShopCard extends Component {

  static propTypes = {
    name: Type.string,
    id: Type.number,
    price: Type.number,
    stock: Type.number,
    limit: Type.number,
    handleClick: Type.func,
    image: Type.string,
    isDisabled: Type.bool,
    isSelected: Type.bool,
  };

  btnClick = () => {
    const { id, handleClick, isDisabled, isSelected } = this.props;
    if (isDisabled || isSelected) return;
    handleClick(id)
  }

  render() {
    const { name, price, stock, limit, image, isSelected, isDisabled } = this.props;
    return (
      <div className={cn({
        root: true,
        isSelected,
        isDisabled
      })}>
        <div className={styles.image} style={{ backgroundImage: `url(${image})`}}>
          <img src={image} alt={name} />
          <div className={styles.bage}>Товар добавлен в корзину</div>
        </div>
        <div className={styles.footerWrapper}>
          <div className={styles.footer}>
            <div className={styles.footerInner}>
              <div className={styles.footerContent}>
                <div className={styles.name}>
                  <span className={styles.nameInner}>{name}</span>
                </div>
                <div className={styles.stock}>
                  <div className={styles.stockLabel}>Осталось</div>
                  <div className={styles.stockText}>{stock}/{limit}</div>
                </div>
              </div>
              <div className={styles.footerAside}>
                {!isDisabled && (
                  <div className={styles.price}>
                    <div className={styles.priceInner}>
                      <div className={styles.priceLabel} title="Omen Loyalty Points">OLP</div>
                      <div className={styles.priceText}>{price}</div>
                    </div>
                  </div>)}
              </div>
            </div>
          </div>
          {!isDisabled && (<div className={styles.btnWrapper}>
            <button className={styles.btn} onClick={this.btnClick}><span>Добавить в корзину</span></button>
          </div>)}
        </div>
      </div>
    );
  }
}

export default ShopCard;
