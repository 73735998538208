import React, { Component } from 'react'
import Type from 'prop-types';
import styles from './SmallProductCard.module.scss';
import ClassNames from 'classnames/bind';
import { ReactComponent as IconArrow } from '../../../assets/images/svg/arrow.svg';
import { StripeGenerator } from '../../../components'

const cn = ClassNames.bind(styles);

export default class SmallProductCard extends Component {
  static propTypes = {
    amount: Type.number,
    name: Type.string,
    image: Type.string,
    onClick: Type.func,
  }

  renderImage() {
    const { image } = this.props;
    if (image) {
      return (<div className={styles.image} style={{backgroundImage: `url(${image})`}} />)
    }
    return (<div className={styles.image}>
      <StripeGenerator
        space={15}
        strokeWidth={3}
        color="#fff"
        className={styles.stripe}
      />
    </div>)
  }

  render() {
    const { name, amount, onClick, description } = this.props;
    return (
      <div className={cn({ root: true, hoverable: !!onClick })} onClick={onClick}>
        <div className={styles.data}>
          <div className={styles.meta}>
            <div className={styles.title}>{name}</div>
            {description && <div className={styles.descr}>{description}</div>}
          </div>
          <div className={styles.box}>
            {
              amount >= 0 ?
                <><div className={styles.label}>Осталось</div>
                  <div className={styles.amount}>{amount}</div></>
                : <IconArrow className={styles.arrow} />
            }
          </div>
        </div>
        <div className={styles.cover}>
          {this.renderImage()}
        </div>
      </div>
    )
  }
}
