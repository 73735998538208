export const dataLayers = {
  wantToKnow: { 'event':'uEvent', 'eventCategory': 'Main', "eventAction":"WantToKnow" },
  games: [
    { 'event':'uEvent', 'eventCategory': 'Practice', "eventAction":"Pudge" },
    { 'event':'uEvent', 'eventCategory': 'Practice', "eventAction":"Void" },
    { 'event':'uEvent', 'eventCategory': 'Practice', "eventAction":"Furion" },
  ],
  team: { 'event':'uEvent', 'eventCategory': 'Team', "eventAction":"AssembleTeam" },
  knowMore: { 'event':'uEvent', 'eventCategory': 'Products', "eventAction":"KnowMore" },
  auth: { 'event':'uEvent', 'eventCategory': 'Registration', "eventAction":"SteamAuthorization" },
  confirmRegistration: { 'event':'uEvent', 'eventCategory': 'Registration', "eventAction":"ConfirmRegistation"}
};
