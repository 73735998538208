import React, { Component } from 'react';
import {StripeGenerator, Button } from '../../components';
import { withRouter } from 'react-router-dom'
import bg1 from '../Mechanics/bg-1.png';
import bg2 from '../Mechanics/bg-2.png';
import bg3 from '../Mechanics/bg-3.png';
import bg4 from '../Mechanics/bg-4.png';
import bg5 from '../Mechanics/bg-5.png';
import bg6 from '../Mechanics/bg-6.png';
import img1 from '../Mechanics/img-1.png';
import img2 from '../Mechanics/img-2.png';
import img3 from '../Mechanics/img-3.png';
import img4 from '../Mechanics/img-4.png';
import img5 from '../Mechanics/img-5.png';
import img6 from '../Mechanics/img-6.png';
import { ReactComponent as Chart } from '../Mechanics/graphic.svg';
import { AuthContext } from "../../App";
import styles from './MobileMechanics.module.scss';

class MobileMechanics extends Component {
  handleClick(event, isAuthorized, handleOpen) {
    if (isAuthorized) {
      this.props.history.push('/#tasks-section')
    } else {
      event.preventDefault()
      handleOpen()
    }
  }

  componentDidMount() {
    this.props.onSetHomePage(false);
  }

  render() {
    return (
      <AuthContext.Consumer>
        {({ isAuthorized, handleOpen })=> (
          <div className={styles.wrapper}>
            <section className={styles.sectionIntro}>
              <div className={styles.textBlock}>
                <h1 className={styles.title}>Играй<br />лучше, чем вчера<br /> с OMEN by HP</h1>
                <p className={styles.description}>Совершенствуйся в&nbsp;Dota&nbsp;2 и CS:GO&nbsp;— тренируйся, выполняй ежедневные задания, покупай технику OMEN by&nbsp;HP, собирай команду. Играй на&nbsp;своем максимуме.
</p>
              </div>
              <div className={styles.bg}></div>
              <div className={styles.fg}></div>
            </section>

            <section className={styles.slider}>
              <div className={styles.sliderWrapper}>
                <div className={styles.slide1}>
                  <div className={styles.slideLeft}>
                    <img src={bg1} className={styles.bg1} alt="bg1" />
                    <img src={bg2} className={styles.bg1} alt="bg2" />
                    <img src={bg3} className={styles.bg1} alt="bg3" />
                    <img src={bg4} className={styles.bg1} alt="bg4" />
                    <img src={bg5} className={styles.bg1} alt="bg5" />
                    <img src={bg6} className={styles.bg1} alt="bg6" />
                  </div>
                  <div className={styles.slideRight}>
                    <h2 className={styles.slideTitle}>Что такое OLP?</h2>
                    <p className={styles.slideDescription}>Omen loyalty points (OLP)&nbsp;— это виртуальная валюта OMEN by&nbsp;HP. Чем лучше ты играешь в&nbsp;Dota&nbsp;2 и CS:GO, чем чаще тренируешься, тем больше OLP ты зарабатываешь. За&nbsp;OLP можно купить крутой мерч в&nbsp;магазине на&nbsp;сайте.</p>
                    <Chart className={styles.chart} />

                    <h2 className={styles.slideTitle}>Как зарабатывать OLP?</h2>
                    <p className={styles.slideDescription}>Выполняй ежедневные задания в&nbsp;Dota&nbsp;2 и CS:GO. Каждый день появляются новые. Выбирай, что сделать сегодня, — перебить 1000 крипов или подобрать 20 баунти рун? Система автоматически начислит тебе OLP, если ты выполнишь задание. OLP начисляются в&nbsp;день окончания задания.</p>
                    <img src={img1} className={styles.screenshot} alt="img1" />

                    <p className={styles.slideDescription}>Тренируйся в&nbsp;мини-играх по&nbsp;мотивам Dota 2. Раз в&nbsp;сутки ты можешь заработать OLP в&nbsp;каждой из&nbsp;трёх игр.</p>
                    <img src={img2} className={styles.screenshot} alt="img2" />

                    <p className={styles.slideDescription}>Покупай технику OMEN by&nbsp;HP, загружай чек на&nbsp;сайт&nbsp;— если чек действителен, мы зачислим на&nbsp;вой счет OLP.</p>
                    <img src={img3} className={styles.screenshot} alt="img3" />

                    <h2 className={styles.slideTitle}>Как потратить OLP?</h2>
                    <p className={styles.slideDescription}>Заходи в&nbsp;магазин OLP&nbsp;— там ты можешь обменять валюту на&nbsp;мерч, билеты, разборы игр и многое другое. Ассортимент магазина будет пополняться. Количество товаров ограничено.</p>
                    <img src={img4} className={styles.screenshot} alt="img4" />

                    <h2 className={styles.slideTitle}>Где посмотреть количество OLP на&nbsp;счету?</h2>
                    <p className={styles.slideDescription}>Мы добавили личный кабинет, в&nbsp;котором ты найдешь список всех действий, за&nbsp;которые ты получил OLP. Там же&nbsp;— список покупок и их статус.</p>
                    <img src={img5} className={styles.screenshot} alt="img5" />

                    <h2 className={styles.slideTitle}>Как работает рейтинг?</h2>
                    <p className={styles.slideDescription}>Рейтинг выстраивается по&nbsp;количеству суммарно накопленных OLP игроками за&nbsp;все время. У&nbsp;нас есть отдельные рейтинги по&nbsp;Dota&nbsp;2 и CS:GO и общий.</p>
                    <img src={img6} className={styles.screenshot} alt="img6" />

                    <div className={styles.box}>
                      <div className={styles.boxWrapper}>
                        <div className={styles.boxCaption}>Присоединяйся к сообществу!</div>
                        <Button
                          text="Участвовать"
                          slide="red"
                          onClick={(e) => this.handleClick(e, isAuthorized, handleOpen)}
                          href="/#tasks-section"
                        />
                      </div>
                      <div className={styles.boxSquare}>
                        <StripeGenerator
                          space={14}
                          className={styles.boxPattern}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default withRouter(MobileMechanics);
