export const parseOrders = (orders) => {
  return orders.results.reduce((acc, order) => {
    return [...acc, ...order.order_products]
  }, []).map(product => {
    const { name, type } = product.product;
    const { price, quantity, ticket, bootcamp_date, finished, blast } = product
    const summ = price * quantity;
    let code;
    if (blast) {
      code = blast.code
    } else if (ticket) {
      code = ticket.code
    }
    return{
      summ,
      name,
      type,
      finished,
      code: code,
      date: bootcamp_date && bootcamp_date.date,
      time: bootcamp_date && bootcamp_date.time_start,
    }
  })
}