export const tabs1 = [
  {
    label: 'Топ 10',
  },
  {
    label: 'Топ 100',
  },
];

export const tabs2 = [
  {
    label: 'Топ 10',
  },
  {
    label: 'Топ 100',
  },
  {
    label: 'Где я?',
  },
];
