import React, { Component } from 'react';
import { AuthContext } from '../../App';

class AuthProvider extends Component {
  render (){
    return (
      <AuthContext.Provider value={this.props.value}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export default AuthProvider;
