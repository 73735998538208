import React, { Component } from 'react'
import styles from './FormControl.module.scss';
import classNames from 'classnames/bind';
const cn = classNames.bind(styles);
export default class FormControl extends Component {
  render() {
    return (
      <div className={cn({root: true, onError: !!this.props.error})}>
        {this.props.children}
        {this.props.error ? <div className={cn({error: true})}>{this.props.error}</div> : null}
      </div>
    )
  }
}
