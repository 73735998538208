import React, { Component } from 'react';
import styles from './CartProduct.module.scss';
import classNames from 'classnames/bind';
const cn = classNames.bind(styles);

class CartProduct extends Component {
  state = {
    isRemoved: false
  };

  handleRemove = () => {
    this.setState({ isRemoved: true });
    setTimeout(() => {
      this.props.onRemove(this.props.id)
    }, 600)
  }

  getFormattedPrice = () => {
    try {
      const digit = +this.props.price;
      return digit.toLocaleString('ru-RU')
    } catch(err) {
      return this.props.price
    }
  }

  render() {
    const { name, image, text, children, error } = this.props;
    return (
      <div className={cn({
        root: true,
        isRemoved: this.state.isRemoved,
        hasError: !!error
      })}>
        <div className={styles.inner}>
          <div className={styles.image} style={{ backgroundImage: `url(${image})` }}>
            <img src={image} alt={name} />
          </div>
          <div className={styles.body}>
            <div className={styles.name}>{name}</div>
            <div className={styles.text}>{text}</div>
          </div>
          <div title="Стоимость в OLP (Omen Loyalty Points)" className={styles.price}>{this.getFormattedPrice()}</div>
        </div>
        <div className={styles.footer}>{children && <div>{children}</div>}</div>
        <div className={styles.error}>{error}</div>
        <button title="Удалить из корзины" className={styles.removeBtn} onClick={this.handleRemove}></button>
      </div>
    );
  }
}

export default CartProduct;
