import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import { Input } from '../../components';
import { ReactComponent as IconArrow } from '../../assets/images/svg/arrow-3.svg';
import styles from './Select.module.scss';

const cn = classNames.bind(styles);

class Select extends Component {
  static propTypes = {
    name: Type.string,
    placeholder: Type.string,
    defaultValue: Type.string,
    fullWidth: Type.bool,
    showArrow: Type.bool,
    isReadonly: Type.bool,
    options: Type.arrayOf(Type.shape({
      value: Type.string,
    })).isRequired,
    onChange: Type.func,
  };

  static defaultProps = {
    backgroundColor: '#000',
    showArrow: true,
  };

  state = {
    isOpen: false,
    value: this.props.defaultValue || ''
  };

  componentDidUpdate() {
    const { isOpen } = this.state

    setTimeout(() => {
      if (isOpen) {
        window.addEventListener('click', this.handleClose);
      }
      else {
        window.removeEventListener('click', this.handleClose);
      }
    }, 0);
  }


  componentWillUnmount(){
    window.removeEventListener('click', this.handleClose);
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  handleTogglePopup = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  handleClick = (e) => {
    e.stopPropagation();
  }

  selectItem = (value) => () => {
    this.changeValue(value);
  }

  changeValue(value) {
    const { name, onChange } = this.props;

    if (this.props.value === undefined) {
      this.setState({ value, isOpen: false });
    } else {
      this.setState({ isOpen: false });
    }

    if (onChange) {
      onChange({ name, value });
    }
  }

  render() {
    const {
      name,
      placeholder,
      options,
      fullWidth,
      isReadonly,
      showArrow,
    } = this.props;

    const { isOpen } = this.state;

    const mods = {
      select: true,
      fullWidth,
      isReadonly,
    };

    const value = this.props.value !== undefined
        ? this.props.value
        : this.state.value;

    return (
      <div
        className={cn(mods)}
      >
        <Input
          name={name}
          placeholder={placeholder}
          isReadonly={isReadonly}
          fullWidth={fullWidth}
          iconRight={showArrow && IconArrow}
          value={value}
          onClick={this.handleTogglePopup}
        />

        {isOpen &&
          <div className={styles.popup}>
            <div className={styles.wrapper}>
              <div className={styles.popupBorder}></div>
            </div>

            <div className={styles.menu} onClick={this.handleClick}>
              {options.map((option, idx) =>
                <div
                  key={idx}
                  onClick={this.selectItem(option.value)}
                  className={cn({
                    menuItem: true,
                    active: option.value === value
                  })}
                >
                  <span className={styles.menuItemText}>{option.value}</span>
                </div>
              )}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Select;
