import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { parse, compareAsc, addHours } from 'date-fns'
import { convertToTimeZone } from 'date-fns-timezone'
import { format } from 'date-fns'
import styles from './Account.module.scss';
import { DailyList, FileLoader, Spinner } from '../../components';
import { ModalContext } from "../../App";

import {
    Profile as profileAPI,
    Tasks as tasksAPI,
    Checks as checksAPI,
    Rating as RatingApi } from '../../api';
import { parseOrders } from './utils';

import DailyIcon1 from './dailyIcon1.svg';
import DailyIcon2 from './dailyIcon2.svg';
import DailyIcon3 from './dailyIcon3.svg';
import DailyIcon4 from './dailyIcon4.svg';

const dailyIcons = [
  DailyIcon1,
  DailyIcon2,
  DailyIcon3,
  DailyIcon4
]

const cn = classNames.bind(styles);
class Account extends Component {
  state = {
    orders: [],
    history: [],
    historyLoaded: false,
    ordersloaded: false,
    apiError: false,
    playerRating: null,
    dailyEvents: null,
    loadedChecks: [],
    dailyLoaded: false,
    checkPending: false,
    fileName: null,
    upcomings: [],
  };

  timeSlice = (tasks) => {
    const today = convertToTimeZone(new Date(), { timeZone: 'Europe/Moscow' });

    return tasks.filter((task) => {
      const taskStart = addHours(parse(task.start), 10);
      const taskEnd = addHours(parse(task.end), 10);

      return compareAsc(today, taskStart) > 0 && compareAsc(taskEnd, today) > 0;
    });
  }

  getCheks = () => {
    checksAPI
      .getChecks()
      .then(({ data }) => {
        if(data.operations) {
          const checks = data.operations.map(operation => {
            const status = operation.result === 'recognition failed'
              ? <div className={styles.price}>Ошибка</div> 
              : 'В обработке'
            return {
              date: format(operation.date, 'DD.MM.YYYY HH:mm'),
              status,
              file: operation.file
            }
          });
          this.setState({
            loadedChecks: checks
          })
        }
      })
  }

  handleFileChange = (e, showErrorModal) => {
    const node = e.target;
    const file = node.files && node.files[0];
    if (file) {
      this.setState({ checkPending: true, fileName: file.name });
      window.dataLayer && window.dataLayer.push({
        'event': 'uEvent',
        'eventCategory': 'Shop',
        "eventAction": "CheckRegister"
      });

      const fd = new FormData();
      fd.append('file', file);

      checksAPI
        .postCheck(fd)
        .then(response => {
          if (response.data.error) {
            this.setState({
              checkPending: false,
              fileName: null
            });
            showErrorModal({
              title: 'Ошибка',
              text: 'Не удалось распознать чек'
            });
          } else {
            this.setState({
              checkPending: false,
              fileName: null
            });
            this.getCheks()
          }
          node.value = '';
        })
        .catch(error => {

          this.setState({
            checkPending: false,
            fileName: null
          });

          showErrorModal({
            title: 'Ошибка',
            text: 'Что-то пошло не так.' 
          });

          node.value = '';
        });
    } else {
      this.setState({
        checkPending: false,
        fileName: null
      });
      node.value = '';
    }
  }

  getRating = (params) => {
    RatingApi
      .getRating(params)
      .then(response => {
        const me = response.data.results.find(({ id }) => Number(id) === params.player_id);
        if (me) {
          this.setState({ playerRating: me.place || '-' })
        } else {
          this.setState({ playerRating: '-' })
        }
      })
      .catch(err => {
        this.setState({ playerRating: '-' })
      });
  }
  getOrders = () => {
    profileAPI.getOrders()
      .then(response => {
        const orders = parseOrders(response.data)
        this.setState({
          orders,
          ordersloaded: true
        });
        this.getUpcomings(orders)
      })
      .catch(err => {
        this.setState({
          orders: [],
          ordersloaded: true
        });
      })
  }

  getHistory = () => {
    profileAPI
      .getOLPHistory()
      .then(response => {
        this.setState({
          history: response.data.results,
          historyLoaded: true
        })
      })
      .catch(err => {
        this.setState({
          apiError: true,
          historyLoaded: true
        })
      });
  }

  getTasks = () => {
    tasksAPI.getTasks().then(response => {
      let currentIcon = 0;
      const dailydata = this.timeSlice(response.data)
        .sort((a, b) => {
          return a.joined_task > b.joined_task ? 1 : a.joined_task < b.joined_task ? -1 : 0
        })
        .map((item) => {
          item.icon = dailyIcons[currentIcon];
          if (currentIcon === dailyIcons.length - 1) {
            currentIcon = 0
          } else {
            currentIcon += 1
          }
          return item
        })
      this.setState({
        dailyEvents: dailydata,
        dailyLoaded: true
      })
    }).catch(err => {
      this.setState({
        dailyEvents: [],
        dailyLoaded: true
      })
    })
  }

  async componentDidMount() {
    const { onSetHomePage, getUserInfo } = this.props;
   
    onSetHomePage(false);
    try {
      const userInfo = await getUserInfo();
      if (userInfo && userInfo.id) {
        this.getRating({ player_id: userInfo.id });
        this.getTasks();
        this.getOrders();
        this.getHistory();
        this.getCheks()
      }
    } catch(err) {
      console.log(err)
    }
  }

  getUpcomings = orders => {
    const upcomings = [];
    orders.forEach(order => {
      //"Поездка в буткэмп Team Empire"
      if (order.type === 4) {
        upcomings.push({
          text: 'Твоя поездка в буткэмп состоится:',
          value: `${order.date}, ${order.time}`
        })
      }

      //"Билет в корнер Winstrike"
      if (order.type === 3) {
        upcomings.push({
          text: 'Код для посещения корнера Winstrike:',
          value: `${order.code}`
        })
      }
      //Промокод на бласт
      if (order.type === 5) {
        upcomings.push({
          text: 'Промокод на Blast:',
          value: `${order.code}`
        })
      }
    });

    if (upcomings.length) this.setState({ upcomings })
  }

  renderOrderStatus = order => {
    //"Поездка в буткэмп Team Empire"
    if (order.type === 4) {
      return (
        <>
          <div className={styles.contentText}>{order.date}</div>
          <div className={styles.contentMeta}>{order.time}</div>
        </>
      )
    }

    //"Билет в корнер Winstrike"
    if (order.type === 3 || order.type === 5) {
      return (
        <>
          <div className={styles.contentText}>{order.code}</div>
          <div className={styles.contentMeta}>Ваш код</div>
        </>
      )
    }

    // Для всего остального пишем просто статус
    return (
      <div className={styles.contentText}>
        {order.finished ? 'Обработан' : 'В обработке' }
      </div>
    )
  }

  renderChecks = () => {
    const { loadedChecks } = this.state;
    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th><div className={styles.contentTitle}>Мои чеки</div></th>
            <th colSpan="2"><span className={styles.contentLabel}>Статус</span></th>
          </tr>
        </thead>
        <tbody>
          {
            loadedChecks.map((check, idx) => (
              <tr key={idx}>
                <td>
                  <div className={styles.contentText}>
                    <a
                      className={styles.checksLink}
                      href={`https://checks.omen2play.ru/uploads/${check.file}`}
                      target="_blank"
                      rel="noopener noreferrer">{check.date}</a>
                  </div>
                </td>
                <td colSpan="2"><div className={styles.contentText}>{check.status}</div></td>
              </tr>
            ))
          }
        </tbody>
      </table>
    )
  }
  
  renderOrders = () => {
    const { orders, ordersloaded } = this.state;
    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th><div className={styles.contentTitle}>Мои покупки</div></th>
            <th><span className={styles.contentLabel}>Статус</span></th>
            <th><span className={styles.contentLabel}>Стоимость OLP</span></th>
          </tr>
        </thead>
        <tbody>
          {
            ordersloaded 
            ? orders.map((n, idx) => {
              return (
                <tr key={idx}>
                  <td><div className={styles.contentText}>{n.name}</div></td>
                  <td>{this.renderOrderStatus(n)}</td>
                  <td><div className={styles.price}>{n.summ.toLocaleString('ru-RU')}</div></td>
                </tr>
              )
            })
            : <tr><td colSpan="3"><div className={styles.spinnerWrapper}><Spinner size="40px" /></div></td></tr>
          }
          {(ordersloaded && orders.length === 0) &&
            <tr>
              <td colSpan="3" style={{ textAlign: 'left' }}>Пока ничего не куплено</td>
            </tr>
          } 
        </tbody>
      </table>
    )
  }

  renderHistoryItems = () => {
    const { history } = this.state;
    if (history.length) {
      return history.map((historyItem, idx) => {
        return (
          <tr key={`table-row-${idx}`}>
            <td>
              <div>
                <div className={styles.contentText}>{historyItem.title}</div>
                <div className={styles.contentMeta}>{historyItem.description}</div>
              </div>
            </td>
            <td><div className={styles.price}>{historyItem.points.toLocaleString('ru-RU')}</div></td>
          </tr>
        )
      })
    }
    
    return (
      <tr>
        <td colSpan="3" style={{textAlign: 'left'}}>Ты пока не заработал OLP</td>
      </tr>
    )
  }

  renderHistory = () => {
    const { historyLoaded } = this.state;
    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th><div className={styles.contentTitle}>История заработка OLP</div></th>
            <th><span className={styles.contentLabel}>Стоимость OLP</span></th>
          </tr>
        </thead>
        <tbody>
          {
            historyLoaded 
            ? this.renderHistoryItems()
              : <tr><td colSpan="2"><div className={styles.spinnerWrapper}><Spinner size="40px" /></div></td></tr>
          }
        </tbody>
      </table>
    )
  }

  renderUpconings = ()=> {
    const { upcomings } = this.state;

    return (
      <div className={styles.section}>
        <div className={styles.contentSubTitle}>Предстоящие события</div>
        <div className={styles.asideText}>Сохрани, чтобы не потерять!</div>
        <div>
          {
            upcomings.map((item, idx) => (
              <div key={`upcoming-${idx}`} className={styles.upcoming}>
                <div className={styles.upcomingLabel}>{ item.text }</div>
                <div className={styles.upcomingData}>{ item.value }</div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  render() {
    const { user } = this.props;
    return(
      <ModalContext.Consumer>
        {
          ({ onShowAppError }) => (
            <div className={styles.root}>
              <div className={styles.row}>
                <div className={cn({
                  column: true,
                  profileColumn: true
                })}>
                  <div className={styles.avatar} style={{
                    backgroundImage: user ? `url(${user.avatar})` : 'none'
                  }}>
                  </div>

                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                      <div className={styles.label}>Мой ник</div>
                      <span onClick={this.props.signOut} className={cn({
                        link: true
                      })}>Выйти</span>
                    </div>
                    <div className={styles.title}><span className="truncated">{user ? user.nick_name : '---'}</span></div>
                  </div>

                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                      <div className={styles.label}>Количество OLP</div>
                    </div>
                    <div className={styles.title}>{user ? user.points_OLP.toLocaleString('ru-RU') : '---'}</div>
                  </div>

                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                      <div className={styles.label}>Место в общем рейтинге</div>
                    </div>
                    <div className={styles.title}>
                      {this.state.playerRating ? this.state.playerRating.toLocaleString('ru-RU') : <Spinner cName={styles.ratingSpinner} />}
                    </div>
                    {/* <a href="/#ratings-section"
                      className={cn({
                        link: true
                      })}>
                      [Смотреть таблицу]
                    </a> */}
                  </div>
                </div>

                <div className={cn({
                  column: true,
                  columnGrow: true
                })}>
                  <div className={styles.row}>
                    <div className={cn({
                      column: true,
                      contentColumn: true
                    })}>
                      <div className={cn({
                        section: true,
                        upload: true
                      })}>
                        <div className={styles.contentTitle}>Регистрация чеков за OLP</div>
                        <div className={styles.uploadContent}>
                          <div className={styles.contentMeta}>Купи технику OMEN by&nbsp;HP, загрузи чек и&nbsp;получи OLP!<br />На&nbsp;обработку чека понадобится время&nbsp;&mdash; следи за&nbsp;статусом в&nbsp;личном кабинете.</div>
                        </div>
                        <div className={styles.uploadControl}>
                          <FileLoader
                            placeholder={this.state.fileName}
                            accept={'image/*'}
                            pending={this.state.checkPending}
                            handler={e => this.handleFileChange(e, onShowAppError)} />
                        </div>
                      </div>
                      {this.state.loadedChecks.length > 0 && this.renderChecks()}
                      {this.renderOrders()}
                      {this.renderHistory()}
                    </div>

                    <div className={cn({
                      column: true,
                      asideColumn: true
                    })}>
                      <div className={styles.section}>
                        <div className={styles.contentSubTitle}>Ежедневные задания</div>
                        <div className={styles.asideText}>Не хватает OLP? Зарабатывай больше!</div>
                        <div className={styles.daily}>
                          {
                            this.state.dailyLoaded
                              ? <DailyList list={this.state.dailyEvents} />
                              : <div className={styles.spinnerWrapper}><Spinner /></div>
                          }
                        </div>
                      </div>

                      {this.state.upcomings.length > 0 ? this.renderUpconings() : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </ModalContext.Consumer>
    )  
  }
}

export default Account;