export const orders = [
  {
    name: "Футболка Омен",
    finished: false,
    summ: 890,
    id: 1,
    type: 3
  },
  {
    name: "Разбор игры от профи",
    finished: false,
    summ: 123,
    id: 2,
    type: 2
  },
  {
    name: "Билет в корнер Winstrike",
    finished: true,
    summ: 3500,
    id: 3,
    type: 1,
    code: 'ZSX-3205L',
  },
  {
    name: "Поездка в буткэмп Team Empire",
    finished: true,
    summ: 95000,
    date: '13.08.2019',
    time: '10:00-13:00',
    id: 4,
    type: 4,
  }
]

export const historyOLP = [
  {
    id: 1,
    title: "Смертельный хэдшот",
    summ: 1200,
    text: "Убейте двоих игроков одним хэдшотом"
  },
  {
    id: 2,
    title: "Мастер слона",
    summ: 3000,
    text: "Убейте троих игроков подряд из слона"
  },
  {
    id: 3,
    title: "На ножах",
    summ: 1500,
    text: "Выиграйте 5 раундов на ножах в соревновательном режиме"
  },
  {
    id: 4,
    title: "Дай пушке шанс",
    summ: 1500,
    text: "Выиграйте 25 раундов на пистолетах в соревновательном режиме"
  },
  {
    id: 5,
    title: "Война на истощение",
    summ: 2500,
    text: "Останьтесь последним выжившим в команде из пяти человек"
  },
  {
    id: 6,
    title: "Военные трофеи",
    summ: 2500,
    text: "Останьтесь последним выжившим в команде из пяти человек"
  },
  {
    id: 7,
    title: "Дай пушке шанс",
    summ: 3500,
    text: "Выиграйте 25 раундов на пистолетах в соревновательном режиме"
  }
]

export const dailyEvents = [
  {
    id: 1,
    name: "Выиграй за террористов 3 раунда подряд",
    completed: false,
    icon: "https://via.placeholder.com/15"
  },
  {
    id: 2,
    name: "Одержите победу в раунде так, чтобы не погиб ни один вражеский игрок",
    completed: false,
    icon: "https://via.placeholder.com/15"
  },
  {
    id: 3,
    name: "Выиграй за террористов 3 раунда подряд",
    completed: true,
    icon: "https://via.placeholder.com/15"
  },
  {
    id: 4,
    name: "Останьтесь последним выжившим в команде из пяти человек",
    completed: true,
    icon: "https://via.placeholder.com/15"
  }
]