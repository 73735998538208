import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import lottie from 'lottie-web';
import animationData from "./data.json";
import styles from './Graphic.module.scss';

const cn = classNames.bind(styles);

class Graphic extends Component {
  static propTypes = {
    progress: Type.number,
  };

  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.lanimation = lottie.loadAnimation({
      container: this.canvasRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData,
    });
  }

  componentDidUpdate() {
    const { progress } = this.props;
    const currentFrame = (this.lanimation.totalFrames - 1) / 100 * (progress * 100);
    this.lanimation.goToAndStop(currentFrame, true);
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div
        className={cn({
          graphic: true,
        })}
        ref={this.canvasRef}>
      </div>
    )
  }
}

export default Graphic;
