import { request } from './instance';

const Auth = {
  signIn(data) {
    return request({
      url: 'sign_in/',
      method: 'POST',
      data,
    });
  },
  signUp(data) {
    return request({
      url: 'sign_up/',
      method: 'POST',
      data,
    });
  },
  isUserExist(stemId) {
    return request({
      url: `player_exist/${stemId}`,
      method: 'GET'
    });
  }
};

export default Auth;