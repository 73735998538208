import React, { Component } from 'react';
import Type from 'prop-types'
import ScrollMenu from "react-horizontal-scrolling-menu";
import ProductItem from './ProductItem';
import styles from './ProductCarousel.module.scss';
import ReactDOM from "react-dom";

class ProductCarousel extends Component {
  static propTypes = {
    items: Type.arrayOf(Type.shape({
      id: Type.number,
      name: Type.string,
      description: Type.string,
      url_hp: Type.string,
      image: Type.string,
    })).isRequired,
  };

  getItems = () => {
    const { items } = this.props;

    return items.map((item, index) => ProductItem(item, index, items.length));
  };

  onSelect = () => {
    this.forceUpdate()
  }

  handleScroll = (event) => {
    event.preventDefault();
  }

  componentDidMount() {
    const dom = ReactDOM.findDOMNode(this);
    dom.addEventListener('wheel', this.handleScroll, { passive: false });
    dom.addEventListener('onmousewheel', this.handleScroll); // older browsers, IE
  }

  componentWillUnmount() {
    const dom = ReactDOM.findDOMNode(this);
    dom.removeEventListener('wheel', this.handleScroll);
    dom.removeEventListener('onmousewheel', this.handleScroll);
  }

  render() {
    const items = this.getItems();


    return (
      <div>
        <ScrollMenu
          data={items}
          hideArrows={true}
          scrollBy={1}
          transition={1}
          alignCenter={false}
          innerWrapperClass={styles.inner_wrapper}
          itemClass={styles.item_wrapper}
          itemClassActive={styles.selected}
          wrapperClass={styles.wrapper}
          onSelect={this.onSelect}
          selected={this.props.items.length}
        />
      </div>
    );
  }
}

export default ProductCarousel;
