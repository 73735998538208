import { request } from './instance';

const Game = {
  start() {
    return request({
      url: 'game_start/',
      canApplyToken: true,
    });
  },
};

export default Game;
