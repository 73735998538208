import React, { Component } from 'react';
import Type from 'prop-types'
import { uuidv4 } from '../../utils/uuid';

class StripeGenerator extends Component {
  static propTypes = {
    angle: Type.number,
    strokeWidth: Type.number,
    color: Type.string,
    space: Type.number,
    className: Type.string,
  };

  static defaultProps = {
    angle: 135,
    strokeWidth: 1,
    color: '#fff',
    opacity: 1,
  };

  state = {
    id: '',
  };

  componentDidMount() {
    const id = uuidv4();
    this.setState({ id });
  }

  render() {
    const { id } = this.state;
    const { angle, space, color, strokeWidth, opacity, className } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        className={className}
      >
        <defs>
          <pattern id={id} patternUnits="userSpaceOnUse" width={space} height={space} patternTransform={`rotate(${angle})`}>
            <line x1="0" y="0" x2="0" y2={space} stroke={color} strokeWidth={strokeWidth} />
          </pattern>
        </defs>

        <rect width="100%" height="100%" fill={`url(#${id})`} opacity={opacity} />
      </svg>
    );
  }
}

export default StripeGenerator;
