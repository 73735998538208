import { ReactComponent as Icon1 } from '../../../assets/images/svg/figure-1.svg';
import { ReactComponent as Icon2 } from '../../../assets/images/svg/figure-2.svg';
import { ReactComponent as Icon3 } from '../../../assets/images/svg/figure-3.svg';
import { ReactComponent as Icon4 } from '../../../assets/images/svg/figure-4.svg';

export default [
  {
    icon: Icon1,
    label: 'Точность',
  },
  {
    icon: Icon2,
    label: 'Скорость',
  },
  {
    icon: Icon3,
    label: 'Контроль',
  },
  {
    icon: Icon4,
    label: 'Реакция',
  },
];
