import React, { Component } from 'react';
import { Bar } from '../../components';
import { Counter } from '../../components';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import { ReactComponent as IconArrow } from '../../assets/images/svg/arrow-2.svg';

import styles from './RatingsList.module.scss';
import ScrollOffsetBox from "../ScrollOffsetBox/ScrollOffsetBox";

const cn = classNames.bind(styles);

class RatingsList extends Component {
  static propTypes = {
    playerId: Type.string,
    activeTab: Type.number,
    items: Type.arrayOf(Type.shape({
      id: Type.string,
      nick_name: Type.string,
      points_OLP: Type.number,
      place: Type.number,
      order_rating: Type.shape({
        now_place: Type.number,
        previous_place: Type.number,
      }),
    }))
  };

  calcRank = (place, rating, points) => {
    let rankStatus = 'unchanged';

    if (points !== 0) {
      if (place === rating['now_place']) {
        if (place < rating['previous_place']) {
          rankStatus = 'up';
        } else if (place > rating['previous_place']) {
          rankStatus = 'down';
        }
      } else {
        if (place < rating['now_place']) {
          rankStatus = 'up';
        } else if (place > rating['now_place']) {
          rankStatus = 'down';
        }
      }
    }

    return rankStatus;
  }

  renderItem(item) {
    const { activeTab, playerId, items } = this.props;
    const rankStatus = this.calcRank(item.place, item.order_rating, item.summary_points_OLP);
    const currentPoints = item.summary_points_OLP;
    const maxPoints = items[0].summary_points_OLP;
    const lineWidth = Number((currentPoints / maxPoints).toFixed(2));
    let barFillColor = undefined;

    if (activeTab === 2) {
      if (item.id === playerId) {
        barFillColor = '#ea002a';
      } else {
        barFillColor = '#383838';
      }
    }

    return (
      <div
        key={item.id}
        className={cn({
          item: true,
          active: (item.id === playerId && activeTab === 2),
        })}
      >
        <div
          className={styles.nickname}
          title={item.nick_name}
        >
          <a
            className={styles.steamlink}
            href={`https://steamcommunity.com/profiles/${item.id_steam}/`}
            target="_blank"
          >{item.nick_name}</a>
        </div>
        <Bar
          width={lineWidth}
          fillColor={barFillColor}
          tableRef={this.props.tableRef}
        />
        <ScrollOffsetBox offset={20} initialOffset={0} types={['counter']} additionalElement={this.props.tableRef.current}>
          <Counter
            currentPoints={currentPoints}
          />
        </ScrollOffsetBox>
        <div
          className={styles.line}
        >
        </div>
        <div className={styles.rankStatus}>
          <IconArrow
            className={cn({
              icon: true,
              [`icon_${rankStatus}`]: true,
            })}
          />
        </div>
        <div className={styles.rank}>{item.place}</div>
      </div>
    );
  }

  render() {
    const { items } = this.props;

    return (
      <div
        className={styles.list}
        ref={this.tableRef}
      >
        {items.map((item) => this.renderItem(item))}
      </div>
    )
  }
}

export default RatingsList;
