import React, { Component } from 'react';
import Type from 'prop-types'
import { ReactComponent as LogoOmen } from '../../assets/images/svg/logos/omen.svg';
import { ReactComponent as LogoFistashki } from '../../assets/images/svg/logos/fistashki.svg';
import socials from './mock-data';
import styles from './Footer.module.scss';

class Footer extends Component {
  static propTypes = {
    data: Type.shape({
      about: Type.string,
      legal: Type.string,
      contact_as: Type.string,
      fb: Type.string,
      vk: Type.string,
    }).isRequired
  };

  render() {
    const { data } = this.props;

    return (
      <footer className={styles.footer}>
        <div className={styles.left}>
          <a
            className={styles.logo}
            href="http://bit.ly/2Gv7mXo"
            target="_blank"
            rel='noreferrer noopener'
          >
            <LogoOmen />
          </a>
          <div className={styles.social}>
            {socials.map((social, idx) =>
              <a
                key={idx}
                className={styles.socialLink}
                href={data[social.name]}
                target="_blank"
                rel='noreferrer noopener'
              >
                <span className={styles.socialLinkText}>
                  {social.label}
                </span>
              </a>
            )}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.info}>
            <a
              className={styles.link}
              href={data.legal}
              target="_blank"
              rel='noreferrer noopener'
            >
              Юридические правила
            </a>
            <a
              className={styles.link}
              href="mailto:omen2play@gmail.com"
            >
              Обратная связь {data.contact_as}
            </a>
          </div>

          {/* <a
            className={styles.secondaryLogo}
            href="https://www.fistashki.org/"
            target="_blank"
            rel='noreferrer noopener'
          >
            <LogoFistashki />
          </a> */}
        </div>
      </footer>
    );
  }
}

export default Footer;
