import React, { Component } from 'react';
import ClassNames from 'classnames/bind';
import Type from 'prop-types'
import ReactModal from 'react-modal';
import { StripeGenerator } from '..';
import styles from './BrandModal.module.scss';
const cn = ClassNames.bind(styles);
ReactModal.setAppElement('#root');

class OrderModal extends Component {
  static propTypes = {
    isOpen: Type.bool,
    onClose: Type.func.isRequired,
    wideContent: Type.bool,
    fullSize: Type.bool,
  };

  static defaultProps = {
    fullSize: true,
    wideContent: false,
  }
  render() {
    const {
      isOpen,
      onClose,
      title,
      leftControl,
      rightControl,
      wideContent,
      fullSize,
      children
    } = this.props;
    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel={title}
        onRequestClose={onClose}
        className={cn({ root: true, isWide: wideContent, isFull: fullSize })}
        shouldCloseOnOverlayClick={true}
      >
        <div className={styles.container}>
          <div className={styles.body}>
            <StripeGenerator space={10} color="#000000" className={styles.topStripes} />
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{children}</div>
            <div className={styles.footer}>
              {leftControl && leftControl(styles.btn)}
              {rightControl && rightControl(styles.btnWhite)}
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}


export default OrderModal;