import React, { Component } from 'react'

export default class DataTable extends Component {
  renderBody = () => {
    return this.props.data.map(row => {
      return(<tr>{row.map(col => <td>{col}</td>)}</tr>)
    })
  }

  renderHead = () => {
    return (<tr>{this.props.head.map(colContent => <th>{colContent}</th>)}</tr>)
  }

  render() {
    return (
      <table>
        <thead>{this.renderHead()}</thead>
        <tbody>
          {this.renderBody()}
        </tbody>
      </table>
    )
  }
}
