export default [
  {
    value: 'Hardliner',
  },
  {
    value: 'Mider',
  },
  {
    value: 'Support',
  },
  {
    value: 'Carry',
  },
];
