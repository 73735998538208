import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import styles from './Tabs.module.scss';

const cn = classNames.bind(styles);

class Tabs extends Component {
  static propTypes = {
    items: Type.arrayOf(Type.shape({
      label: Type.string,
    })).isRequired,
    activeTab: Type.number,
    onChange: Type.func,
  };

  handleChange = ({ target }) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(Number(target.id));
    }
  }

  render() {
    const { items, activeTab } = this.props;

    return (
      <div className={styles.tabs}>
        {items.map((item, idx) =>
          <span
            id={idx}
            key={idx}
            onClick={this.handleChange}
            className={cn({
              item: true,
              active: idx === activeTab
            })}
          >
            {item.label}
          </span>
        )}
      </div>
    );
  }
}

export default Tabs;
