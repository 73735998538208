import React, { Component } from 'react';
import Type from 'prop-types'
import { RatingsList, Tabs } from '../../../components';
import { tabs1, tabs2 } from './mock-data';
import { Rating } from '../../../api';
import styles from './SectionRating.module.scss';
import ScrollOffsetBox from "../../ScrollOffsetBox/ScrollOffsetBox";
import ClassNames from 'classnames/bind';

const cn = ClassNames.bind(styles);
class SectionRating extends Component {
  static propTypes = {
    isMobile: Type.bool,
    isAuthorized: Type.bool,
    playerId: Type.string,
  };

  state = {
    activeTab: 0,
    players: [],
    error: null,
    currentGame: null,
    isPending: false
  };

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  handleChange = (activeTab) => {
    this.setState({ activeTab });
  }

  setGame = (game) => {
    this.setState({ currentGame: game });
    this.getItemsByRatingType(this.state.activeTab, game);
  }

  async getItemsByRatingType(type, currentGame) {
    this.setState({ isPending: true })
    const { playerId } = this.props;
    const pageSize = type === 1 ? 100 : 10;
    let data = { page_size: pageSize };

    if (type === 2) {
      data['player_id'] = playerId;
    }

    if (currentGame) {
      data['game'] = currentGame
    }

    const items = await Rating.getRating(data);
    this.setState({ isPending: false })
    return items;
  }

  async componentDidMount() {
    try {
      const game = this.props.currentGame;
      if (game) {
        this.setState({
          currentGame: game
        })
      }
      const players = await this.getItemsByRatingType(this.state.activeTab, game);
      this.setState({ players: players.data.results });
    } catch (error) {
      this.setState({error})
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.activeTab !== this.state.activeTab ||
      prevProps.isAuthorized !== this.props.isAuthorized ||
      prevState.currentGame !== this.state.currentGame 
    ) {
      try {
        const players = await this.getItemsByRatingType(this.state.activeTab, this.state.currentGame);
        this.setState({ players: players.data.results });
      } catch (error) {
        console.log('rating error ', error);
      }
    }
  }

  render() {
    const { isAuthorized, isMobile, playerId } = this.props;
    const { activeTab, players, currentGame } = this.state;
    const tabs = isAuthorized && !isMobile ? tabs2 : tabs1;
    const { error } = this.state;
    if (error) {
      throw error;
    }

    return (
      <div className={styles.section} id="ratings-section">
        <div className={styles.container}>
          <ScrollOffsetBox offset={50} initialOffset={50} disable={isMobile}>
            <div className={styles.tabs}>
              <div className={styles.tabsHeader}>
                <div className={styles.tabsTitle}>Рейтинг</div>
                <div className={styles.games}>
                  <div
                    className={cn({ gamesItem: true, gamesItemActive: currentGame === 'dota'  })}
                    onClick={() => this.setGame('dota')}>DOTA2</div>
                  <div
                    className={cn({ gamesItem: true, gamesItemActive: currentGame === 'cs' })}
                    onClick={() => this.setGame('cs')}>CS:GO</div>
                  <div
                    className={cn({ gamesItem: true, gamesItemActive: !currentGame })}
                    onClick={() => this.setGame(null)}>Общий</div>
                </div>
              </div>
              <div className={styles.tabsList}>
                <Tabs
                  items={tabs}
                  activeTab={activeTab}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </ScrollOffsetBox>

          <div
            className={styles.ratings}
            ref={this.tableRef}
          >
            <RatingsList
              playerId={playerId}
              items={players}
              activeTab={activeTab}
              tableRef={this.tableRef}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SectionRating;
