import React, { Component } from 'react';
import Type from 'prop-types'
import { parse, compareAsc, addHours } from 'date-fns'
import { convertToTimeZone } from 'date-fns-timezone'
import { SectionHero } from '../../components';
import { AuthContext, DeviceContext, ModalContext } from "../../App";
import { Tasks } from '../../api';

class Home extends Component {
  static propTypes = {
    isAuthorized: Type.bool,
    playerId: Type.string,
  };

  state = {
    tasks: [],
    currentTasks: [
      { text: 'Участвую', counter: 0 },
      { text: 'Не участвую', counter: 4 },
    ],
    error: null
  };

  timeSlice(tasks) {
    const today = convertToTimeZone(new Date(), {timeZone: 'Europe/Moscow'});

    return tasks.filter((task) => {
      const taskStart = addHours(parse(task.start), 10);
      const taskEnd = addHours(parse(task.end), 10);

      return compareAsc(today, taskStart) > 0 && compareAsc(taskEnd, today) > 0;
    });
  }

  getCurrentTasksCount(actualTasks) {
    const totalTaskCount = actualTasks.length;
    const currentTaskCount = actualTasks.filter((task) => task.joined_task > 0).length;
    const currentTasksCounter = [...this.state.currentTasks];
    currentTasksCounter[0].counter = currentTaskCount;
    currentTasksCounter[1].counter = totalTaskCount - currentTaskCount;

    return currentTasksCounter
  }

  async getTasksPolling() {
    clearTimeout(this.getTasksPolling);
    try {
      const tasks = await Tasks.getTasks();
      if (Array.isArray(tasks.data)) {
        const actualTasks = this.timeSlice(tasks.data);

        this.setState({
          tasks: actualTasks.slice(0, 4),
          currentTasks: this.getCurrentTasksCount(actualTasks),
        });

        return setTimeout(this.getTasksPolling.bind(this), 600000);
      }
    } catch (error) {
      this.setState({ error });
    }
  }

  async componentDidMount() {
    this.props.onSetHomePage(true);
    try {
      await this.getTasksPolling()
    } catch (error) {
      this.setState({error})
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.tasks.length !== this.state.tasks.length) {
      try {
        await this.getTasksPolling()
      } catch (error) {
        this.setState({error})
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.getTasksPolling)
  }

  handleJoinTask = (isMobile, onOpenModal) => async (id) => {
    if (!isMobile) {
      await Tasks.joinTask(id);

      const currentTasks = [...this.state.currentTasks]
      currentTasks[0].counter += 1
      currentTasks[1].counter -= 1
      this.setState({currentTasks: currentTasks })
    } else {
      onOpenModal();
    }
  }

  render() {
    const { history } = this.props;

    return (
      <DeviceContext.Consumer>
        {({ isMobile }) => (
          <AuthContext.Consumer>
            {({ isAuthorized }) => (
              <ModalContext.Consumer>
                {({ onOpenModal }) => (
                  <SectionHero history={history} mode="home" />
                )}
              </ModalContext.Consumer>
            )}
          </AuthContext.Consumer>
        )}
      </DeviceContext.Consumer>
    );
  }
}

export default Home;
