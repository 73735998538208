export const menuItems = [
  // {
  //   route: '/',
  //   anchor: 'hero-section',
  //   title: 'Главная',
  // },
  {
    anchor: 'tasks-section',
    title: 'Ежедневные задания',
  },
  // {
  //   anchor: 'games-section',
  //   title: 'Практика',
  // },
  {
    anchor: 'products-section',
    title: 'Техника OMEN',
  },
  {
    anchor: 'ratings-section',
    title: 'Рейтинги',
  },
];
