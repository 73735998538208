import React, { Component } from 'react'
import Type from 'prop-types';
import classNames from 'classnames/bind';
import styles from './SectionShop.module.scss';
import { FileLoader, SmallProductCard, StripeGenerator } from '../../../components';
import { ReactComponent as IconArrow } from '../../../assets/images/svg/arrow.svg';
import { Checks as checksAPI, TextBlocks } from '../../../api';

const cn = classNames.bind(styles);

const smallDescriptors = {
  1: 'Фирменная футболка',
  2: 'Просмотр реплея',
  3: 'Уникальный код для игры',
  4: 'Посмотри, как живут киберспортсмены'
}

export default class SectionShop extends Component {
  static propTypes = {
    isAuthorized: Type.bool,
    openLogin: Type.func,
    products: Type.array,
    reverse: Type.bool,
    onShowAppError: Type.func,
    toggleSuccesCheckModal: Type.func
  };

  state = {
    checkPending: false,
    fileName: null,
    checksCostContent: null,
  }

  componentDidMount() {
    try {
      TextBlocks
        .getBlocks()
        .then(response => {
          if (Array.isArray(response.data)) {
            const checkBlock = response.data.find(block => block.key === 'check');
            if (checkBlock) {
              this.setState({
                checksCostContent: checkBlock.value
              })
            }
          }
        })
    } catch(error) {
      console.log(error)
    }
  }

  handleFileChange = (e) => {
    const { openLogin, isAuthorized, onShowAppError, toggleSuccesCheckModal } = this.props;

    if (!isAuthorized) {
      openLogin();
      return;
    }

    const node = e.target;
    const file = node.files && node.files[0];
    if (file) {
      this.setState({ checkPending: true, fileName: file.name });
      window.dataLayer && window.dataLayer.push({
        'event': 'uEvent',
        'eventCategory':'Shop',
        "eventAction": "CheckRegister" 
      });

      checksAPI
        .postCheck(file)
        .then(response => {
          if (response.data.error) {
            onShowAppError({
              title: 'Ошибка',
              text: 'Не удалось распознать чек'
            });
          } else {
            toggleSuccesCheckModal(true)
          }

          this.setState({
            checkPending: false,
            fileName: null
          });
          node.value = '';
        })
        .catch(error => {
          onShowAppError({
            title: 'Ошибка',
            text: 'Не удалось распознать чек'
          });
          this.setState({
            checkPending: false,
            fileName: null
          });
          node.value = '';
        })
    } else {
      this.setState({
        checkPending: false,
        fileName: null
      });
      node.value = '';
    }
  }

  renderChecksCost() {
    return { __html: this.state.checksCostContent }
  }

  hadleGoToShop = () => {
    const { history } = this.props;
    window.dataLayer && window.dataLayer.push({
      "event": "uEvent",
      "eventCategory": "Shop",
      "eventAction": "GoToShop" 
    });
    history.push('/shop');
  } 

  renderProducts() {
    const { products } = this.props;
    if (products.length === 0) return null;

    const cards = Object.values(products.reduce((acc, val) => {
        if (!acc[val.type] && (val.type === 1 || val.type === 3))
        {
          acc[val.type] = val
        }
        return acc
      }, {}))
      .map(product => {
        let description = smallDescriptors[product.type];
        return (
          <div className={styles.card} key={product.id}>
            <SmallProductCard
              name={product.name}
              image={product.image}
              description={description}
              amount={product.amount} />
          </div>
        )
    });

    const placeholder = (
      <div className={styles.card} key="card-placeholder">
        <SmallProductCard
          key="placeholder"
          onClick={this.hadleGoToShop}
          name="Смотреть больше мерча" />
      </div>
    )

    cards.push(placeholder)
    return cards
  }

  render() {
    const { checksCostContent } = this.state;
    return (
      <div className={styles.root}>
        <div className={cn({row: true, reversed: this.props.reverse})}>
          <div className={cn({ column: true, columnTickets: true })}>
            <div className={styles.cross}></div>
            <StripeGenerator
              space={10}
              strokeWidth={3}
              color="#EA002A"
              className={styles.stripe}
            />
            <div className={styles.checkInner}>
              <div className={styles.title}>Регистрация чеков за OLP</div>
              <div className={styles.text}>
                Купи технику OMEN by&nbsp;HP, загрузи чек и&nbsp;получи OLP! На&nbsp;обработку чека понадобится время&nbsp;&mdash; следи за&nbsp;статусом в&nbsp;личном кабинете.
              </div>
              <div className={styles.loaderWrapper}>
                <FileLoader
                  placeholder={this.state.fileName}
                  accept={'image/*'}
                  pending={this.state.checkPending}
                  handler={this.handleFileChange} />
              </div>

              {
                checksCostContent &&
                <div
                  className={styles.checkRate} 
                  dangerouslySetInnerHTML={this.renderChecksCost()}></div>
              }
            </div>
          </div>
          <div className={cn({ column: true, columnShop: true })}>
            <div className={styles.shopInner}>
              <div className={styles.title}>магазин OLP <IconArrow className={styles.arrow} /></div>
              <div className={styles.text}>Обменивай накопленные OLP на&nbsp;крутой мерч в&nbsp;магазине.</div>
            </div>
            <div className={styles.cardsWrapp}>
              <div className={styles.cards}>
                {this.renderProducts()}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    )
  }
}
