import { request } from './instance';

const Teams = {
  getTeamPlayers() {
    return request({
      url: 'selection_teammate',
      canApplyToken: true,
    });
  },
};

export default Teams;
