import React, {useEffect} from 'react';
import styles from './Unsubscribe.module.scss';

const Unsubscribe = (props) => {
  useEffect(() => {
    props.onSetHomePage(false);
  },[]);

  return (
    <section className={styles.unsubscribe}>
      <div className={styles.bg}></div>
      <div className={styles.text}>Вы успешно отписались от рассылки</div>
    </section>
  )
};

export default Unsubscribe
