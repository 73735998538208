import React, { Component } from 'react';
import Type from 'prop-types'
import { StripeGenerator } from '../../../components';
import styles from './TeamCard.module.scss';

class TeamCard extends Component {
  static propTypes = {
    data: Type.shape({
      role: Type.string,
      nickName: Type.string,
      mmr: Type.number,
    }).isRequired,
    customPointsColor: Type.string,
    backgroundColor: Type.string,
    actionButtonText: Type.string,
    showFooter: Type.bool,
    onClick: Type.func,
  };

  static defaultProps = {
    backgroundColor: '#000',
  };

  handleClick = () => {
    const { onClick } = this.props;

    if (onClick) {
      onClick();
    }
  }

  render() {
    const { backgroundColor, showFooter, actionButtonText, customPointsColor } = this.props;
    const { avatar, mmr, role, nickName, profileUrl } = this.props.data;

    return (
      <div className={styles.card}>
        <div className={styles.header}>
          <div
            className={styles.wrapper}
            style={{ backgroundColor }}
          >
            <div className={styles.content}>
              <div className={styles.role}>{role}</div>
              <div className={styles.nickName}>{nickName}</div>
            </div>
            <div className={styles.stats}>
              <span className={styles.label}>MMR</span>
              <span className={styles.mmr} style={{ color: customPointsColor }}>{mmr}</span>
            </div>
          </div>
        </div>
        <div className={styles.frame}>
          <div className={styles.cover}>
            <div
              className={styles.image}
              style={{backgroundImage: `url(${avatar})`}}
            >
            </div>
          </div>
        </div>

        {showFooter &&
          <div className={styles.footer}>
            <a href={profileUrl}
              className={styles.button}
              style={{ backgroundColor }}
              target="_blank"
              rel='noreferrer noopener'
            >
              <div className={styles.buttonContent}>
                <span className={styles.buttonText}>{actionButtonText}</span>
                <StripeGenerator
                  space={6}
                  angle={45}
                  className={styles.stripe}
                  color="#ea002a"
                />
              </div>
            </a>
          </div>
          }
      </div>
    )
  }
}

export default TeamCard;
