import React, { Component } from 'react';
import { Button, Select, TeamCard } from '../../components';
import options from '../SignUp/mock-data';
import { Teams, Profile as ProfileAPI } from '../../api';
import toCamelCase from '../../utils/toCamelCase';
import styles from './Profile.module.scss';

class Profile extends Component {
  state = {
    profile: {},
    players: [],
  };

  handleChange = ({ name, value }) => {
    const data = { [name]: value };

    this.setState((prevState) => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        [name]: value,
      }
    }));

    this.editProfile(data);
  }

  handleSelectTeamMates = () => {
    this.getTeamPlayers();
  }

  componentDidMount() {
    this.getProfileInfo();
    this.getTeamPlayers();
    this.props.onSetHomePage(false);
  }

  async editProfile(data) {
    try {
      await ProfileAPI.edit(data);
    } catch (error) {
      console.log(error);
    }
  }

  async getProfileInfo() {
    try {
      const profile = await ProfileAPI.info();
      this.setState({ profile: profile.data });
    } catch (error) {
      console.log(error);
    }
  }

  async getTeamPlayers() {
    try {
      const players = await Teams.getTeamPlayers();
      this.setState({ players: players.data });
    } catch (error) {
      console.log(error);
    }
  }

  getNormalizedData() {
    return this.state.players.map(player => {
      const normalizedData = toCamelCase(player);

      return {
        role: normalizedData.playerDotaRole,
        mmr: normalizedData.playerMmrEstimate,
        avatar: normalizedData.playerAvatar184,
        profileUrl: normalizedData.playerProfileUrl,
        nickName: normalizedData.playerNickName,
      }
    });
  }

  renderCards(players) {
    return (
      <div className={styles.cards}>
        {players.map((player, idx) =>
          <div className={styles.item} key={idx}>
            <TeamCard
              data={player}
              actionButtonText="Связаться"
              showFooter
            />
          </div>
        )}
      </div>
    );
  }

  renderEmptyText() {
    return (
      <div className={styles.emptyText}>
        К сожалению, мы пока не можем подобрать тебе тиммейтов — попробуй еще раз, когда больше пользователей зарегистрируется на сайте.
      </div>
    );
  }

  render() {
    const { profile } = this.state;
    const players = this.getNormalizedData();

    return (
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.player}>
              <div
                className={styles.avatar}
                style={{backgroundImage: `url(${profile.avatar})`}}
              >
              </div>
              <div className={styles.info}>
                <div className={styles.infoItem}>
                  <div className={styles.label}>Мой ник</div>
                  <div className={styles.title}>{profile.nick_name}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.label}>Мой MMR</div>
                  <div className={styles.title}>{profile.mmr_estimate}</div>
                </div>

                <Select
                  name="dota_role"
                  placeholder="Моя роль"
                  value={profile.dota_role}
                  options={options}
                  onChange={this.handleChange}
                  fullWidth
                  isReadonly
                />
              </div>
            </div>
            <Button
              text="подобрать еще"
              size="xs"
              slide="red"
              borderColor="#ea002a"
              fingerPrintFillColor="#000"
              dashed
              withFingerPrint
              onClick={this.handleSelectTeamMates}
              stripeProps={{
                height: "18px",
                bottom: "-18px"
              }}
            />
          </div>
          { players.length > 0 ? this.renderCards(players) : this.renderEmptyText() }
        </div>
      </div>
    );
  }
}

export default Profile;
