import React, { Component } from 'react';
import Type from 'prop-types'
import { parse, compareAsc, addHours } from 'date-fns'
import { convertToTimeZone } from 'date-fns-timezone'
import {
  SectionHero,
  SectionTasks,
  SectionProducts,
  SectionRating,
  SectionShop
} from '../../components';
import { AuthContext, DeviceContext, ModalContext } from "../../App";

import { Tasks } from '../../api';

class CSGO extends Component {
  static propTypes = {
    isAuthorized: Type.bool,
    playerId: Type.string,
    onSetHomePage: Type.func,
    onGetProducts: Type.func,
    products: Type.array,
    openLogin: Type.func
  };

  state = {
    tasks: [],
    currentTasks: [
      { text: 'Участвую', counter: 0 },
      { text: 'Не участвую', counter: 4 },
    ],
    error: null,
  };

  timeSlice(tasks) {
    const today = convertToTimeZone(new Date(), { timeZone: 'Europe/Moscow' });
    return tasks.filter((task) => {
      const taskStart = addHours(parse(task.start), 10);
      const taskEnd = addHours(parse(task.end), 10);

      return compareAsc(today, taskStart) > 0 && compareAsc(taskEnd, today) > 0;
    });
  }

  getCurrentTasksCount(actualTasks) {
    const totalTaskCount = actualTasks.length;
    const currentTaskCount = actualTasks.filter((task) => task.joined_task > 0).length;
    const currentTasksCounter = [...this.state.currentTasks];
    currentTasksCounter[0].counter = currentTaskCount;
    currentTasksCounter[1].counter = totalTaskCount - currentTaskCount;

    return currentTasksCounter
  }

  async getTasksPolling() {
    clearTimeout(this.getTasksPolling);

    try {
      const tasks = await Tasks.getTasks({game: 'cs'});
      console.log(tasks);
      if (Array.isArray(tasks.data)) {
        const actualTasks = this.timeSlice(tasks.data);

        this.setState({
          tasks: actualTasks.slice(0, 4),
          currentTasks: this.getCurrentTasksCount(actualTasks),
        });

        return setTimeout(this.getTasksPolling.bind(this), 600000);
      }
    } catch (error) {
      
      //this.setState({ error });
    }
  }

  async componentDidMount() {
    this.props.onSetHomePage(false);
    window.scrollTo(0, 0);
    if (this.props.products.length === 0) {
      this.props.onGetProducts()
    }
    try {
      await this.getTasksPolling()
    } catch (error) {
      //this.setState({ error })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.tasks.length !== this.state.tasks.length) {
      try {
        await this.getTasksPolling()
      } catch (error) {
        //this.setState({ error })
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.getTasksPolling)
  }

  handleJoinTask = (isMobile, onOpenModal) => async (id) => {
    if (!isMobile) {
      await Tasks.joinTask(id);

      const currentTasks = [...this.state.currentTasks]
      currentTasks[0].counter += 1
      currentTasks[1].counter -= 1
      this.setState({ currentTasks: currentTasks })
    } else {
      onOpenModal();
    }
  }

  render() {
    const { playerId } = this.props;

    // if (this.state.error) {
    //   throw this.state.error;
    // }

    const { history } = this.props;
    const { tasks, currentTasks } = this.state;

    return (
      <DeviceContext.Consumer>
        {({ isMobile }) => (
          <AuthContext.Consumer>
            {({ isAuthorized }) => (
              <ModalContext.Consumer>
                {({ onOpenModal, onShowAppError, toggleSuccesCheckModal }) => (
                  <>
                    <SectionHero
                      history={history}
                      mode="cs" />
                    <SectionTasks
                      tasks={tasks}
                      currentTasks={currentTasks}
                      onClick={this.handleJoinTask(isMobile, onOpenModal)}
                    />
                    <SectionShop 
                      products={this.props.products}
                      isAuthorized={isAuthorized}
                      openLogin={this.props.openLogin}
                      history={history} 
                      onShowAppError={onShowAppError}
                      toggleSuccesCheckModal={toggleSuccesCheckModal}
                      reverse
                    />
                    <SectionProducts />
                    <SectionRating
                      isAuthorized={isAuthorized}
                      currentGame="cs"
                      playerId={playerId}
                      isMobile={isMobile}
                    />
                  </>
                )}
              </ModalContext.Consumer>
            )}
          </AuthContext.Consumer>
        )}
      </DeviceContext.Consumer>
    );
  }
}

export default CSGO;