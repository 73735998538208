import { request } from './instance';

const Products = {
  getProducts() {
    return request({
      url: 'game_goods/',
    });
  },
};

export default Products;
