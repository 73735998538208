import { request } from './instance';
const mockedAbout = {
  data: {
    about: "Описание",
    contact_as: "omen2play@gmail.com",
    fb: "http://bit.ly/2Uwjuwt",
    id: 1,
    legal: "https://drive.google.com/file/d/1pH-hdYSbZj6XyyDgMcqlK50VwQGsb64m/view?usp=sharing",
    vk: "http://bit.ly/2Vgl4XD",
    yt: "Ютуб",
  }
}
// Почему-то на локальном сервере постоянно отвалилвался запрос getInfo
// замокал для дева...

const About = {
  getInfo() {
    return process.env.NODE_ENV === 'development' ? Promise.resolve(mockedAbout) : request({
      url: 'about/',
    });
  },
};

export default About;
