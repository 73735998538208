import axios from 'axios';

import { API_URL } from '../config';
const API = axios.create({ baseURL: API_URL });

const getRequestHandler = instance => (options) => {
  const {
    url,
    method = 'GET',
    params = {},
    defaults = {},
    canApplyToken = false,
    mustApplyToken = false,
    token = window.localStorage.getItem('token_access'),
    data = {},
    baseURL,
  } = options;

  if (!token && mustApplyToken) return Promise.reject();

  const config = { url, method, params, data, defaults };

  if (baseURL) {
    config.baseURL = baseURL;
  }

  if (canApplyToken && token) {
    config.headers = {
      'Authorization': token
    };
  }
  // Basic auth на дев сервере
  if (process.env.NODE_ENV === 'development') {
    // config.auth = {
    //   username: process.env.REACT_APP_API_LOGIN,
    //   password: process.env.REACT_APP_API_PASSWORD
    // }
  }

  return instance.request(config);
};

export const request = getRequestHandler(API);
