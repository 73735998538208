import { request } from './instance';

const Rating = {
  getRating(params) {
    return request({
      url: 'top_players/',
      params,
    });
  },
};

export default Rating;
