import React, { Component } from 'react';
import { Button } from '../../components';
import Type from 'prop-types'
import { getSteamAutorizationPath } from "../../constants/autorization";
import { ReactComponent as IconArrow } from '../../assets/images/svg/arrow.svg';
import { dataLayers } from '../../utils/gtm';
import styles from './SignIn.module.scss';

class SignIn extends Component {
  static propTypes = {
    history: Type.shape({
      push: Type.func.isRequired,
    }),
  };

  handleSignIn = () => {
    const currentHostname = window.location.hostname
    window.dataLayer.push(dataLayers.auth);
    window.open(getSteamAutorizationPath(currentHostname, this.props.location.pathname), '_self')
  }

  render() {
    return (
      <>
        <div className={styles.fg}></div>

        <div className={styles.content}>
          <div className={styles.header}>
            <h1 className={styles.title}>Авторизация<br />в Steam</h1>
            <div className={styles.stripe}></div>
          </div>
          <div className={styles.hint}>
            Для дальнейшей регистрации на сайте и участии в проекте, вам необходимо перейти на сайт Steam, чтобы авторизоваться
          </div>

          <Button
            size="xs"
            text="Авторизоваться"
            slide="red"
            icon={<IconArrow />}
            iconPosition="right"
            onClick={this.handleSignIn}
            dashed
            stripeProps={{
              height: "12px",
              top: "-12px"
            }}
          />
        </div>
      </>
    );
  }
}

export default SignIn;
