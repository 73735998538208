import React, { Component } from 'react';
import Type from 'prop-types'
import styles from './Bar.module.scss';
import ScrollOffsetBox from "../ScrollOffsetBox/ScrollOffsetBox";

class Bar extends Component {
  static propTypes = {
    fillColor: Type.string,
    backgroundColor: Type.string,
    width: Type.number,
  };

  static defaultProps = {
    fillColor: '#ea002a',
    backgroundColor: '#4a4a4a',
  };

  render() {
    const { width, fillColor, backgroundColor, tableRef } = this.props;

    return (
      <div className={styles.bar}>
        <div
          className={styles.bg}
          style={{
            'backgroundColor': backgroundColor,
          }}
        >
        </div>

        <ScrollOffsetBox
          offset={20}
          initialOffset={0}
          types={['scale']}
          additionalElement={tableRef.current}
        >
          <div
            className={styles.fill}
            style={{
              backgroundColor: fillColor,
              transform: `scaleX(${width}) translateY(-50%)`,
            }}
          >
          </div>
        </ScrollOffsetBox>
      </div>
    )
  }
}

export default Bar;
