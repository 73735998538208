import React, { Component } from 'react';
import { DeviceContext } from '../../App';

class DeviceProvider extends Component {
  render (){
    return (
      <DeviceContext.Provider value={this.props.value}>
        {this.props.children}
      </DeviceContext.Provider>
    )
  }
}

export default DeviceProvider;
