import React, { Component } from 'react'
import FormControl from './FormControl';

export default class TShirtForm extends Component {
  render() {
    const { labelClass, rowClass, handleChange, userData, errors } = this.props;
    return (
      <div>
        <div className={rowClass}>
          <label className={labelClass}>ФИО<span>*</span></label>
          <FormControl error={errors.fullname}>
            <input type="text" name="fullname" value={userData.fullname} onChange={handleChange}/>
          </FormControl>
        </div>
        <div className={rowClass}>
          <label className={labelClass}>Почтовый адрес доставки<span>*</span></label>
          <FormControl error={errors.delivery_address}>
            <input type="text" name="delivery_address" value={userData.delivery_address} onChange={handleChange} />
          </FormControl>
        </div>
        <div className={rowClass}>
          <label className={labelClass}>Мобильный телефон для связи</label>
          <FormControl>
            <input type="text" name="phone" value={userData.phone}  onChange={handleChange} />
          </FormControl>
        </div>

        <div className={rowClass}>
          <label className={labelClass}>Комментарий к заказу</label>
          <FormControl error={errors.comment}>
            <textarea name="comment" rows="6" value={userData.comment}  onChange={handleChange}></textarea>
          </FormControl>
        </div>
      </div>
    )
  }
}
