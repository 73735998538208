import React, { Component } from 'react';
import { GameCard, TeamCard, StripeGenerator } from '../../../components';
import { gameCards, teamCards, icons } from '../SectionGames/mock-data';
import { ModalContext } from "../../../App";
import styles from './MobileSectionGames.module.scss';

class MobileSectionGames extends Component {
  handlePlayGame = (onOpenModal) => () => {
    onOpenModal();
  }

  renderGames() {
    return (
      <ModalContext.Consumer>
        {({ onOpenModal }) => (
          <div>
            <div className={styles.content}>
              <div className={styles.text}>
                <h1 className={styles.title}>Практика, еще раз практика!</h1>

                <p className={styles.description}>Отточи скиллы в мини-играх! Поймай врага крюком, поставь купол или правильно расставь преграды на пути соперника. Раз в сутки ты можешь получить OLP за успешное прохождение игр.</p>
              </div>
              <div className={styles.gameCards}>
                {gameCards.map((card, idx) =>
                  <div key={idx} className={styles.gameCardItem}>
                    <GameCard
                      data={card}
                      icon={icons[idx]}
                      onClick={this.handlePlayGame(onOpenModal)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </ModalContext.Consumer>
    );
  }

  renderTeams() {
    return (
      <div>
        <div className={styles.content}>
          <div className={styles.text}>
            <h1 className={styles.title}>Собери свою команду</h1>
            <p className={styles.description}>Кто ты? Танк, сапорт или DPS? Подбери себе команду по&nbsp;уровню и&nbsp;тренируйтесь вместе!</p>
          </div>

          <div className={styles.teamCards}>
            {teamCards.map((card, idx) =>
              <div key={idx} className={styles.teamCardItem}>
                <TeamCard
                  data={card}
                  backgroundColor="#ea002a"
                  customPointsColor="#000"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.section} id="games-section">
        <StripeGenerator
          space={8}
          color="#000"
          className={styles.stripe}
        />
        <div className={styles.slides}>
          { this.renderGames() }
          { this.renderTeams() }

          <div className={styles.bg}></div>
        </div>
      </div>
    );
  }
}

export default MobileSectionGames;
