import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import { StripeGenerator } from '../../components';
import { ReactComponent as LogoOmen } from '../../assets/images/svg/logos/omen.svg';
import { ReactComponent as LogoMicrosoft } from '../../assets/images/svg/logos/microsoft.svg';
import { ReactComponent as IconMenu } from '../../assets/images/svg/burger.svg';
import styles from './MobileHeader.module.scss';

const cn = classNames.bind(styles);
const SCROLL_THRESHOLD = '20';

class MobileHeader extends Component {
  rafExecuting = false;

  static propTypes = {
    isHomePage: Type.bool,
    onOpen: Type.func.isRequired
  };

  state = {
    isSticky: false,
  };

  handleScroll = () => {
    if (this.rafExecuting) {
      return;
    }

    this.rafExecuting = true;
    const { isSticky } = this.state;
    const pastScrollThreshold = window.pageYOffset > SCROLL_THRESHOLD;

    requestAnimationFrame(() => {
      if (pastScrollThreshold && !isSticky) {
        this.setState({ isSticky: true });
      } else if (!pastScrollThreshold && isSticky) {
        this.setState({ isSticky: false });
      }

      this.rafExecuting = false;
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { onOpen } = this.props;
    const { isSticky } = this.state;

    return (
      <header
        className={cn({
          header: true,
          isSticky,
        })}
      >
        <div className={styles.fill} />
        <div className={styles.left}>
          <a
            className={styles.logo}
            href="http://bit.ly/2Gv7mXo"
            target="_blank"
            rel='noreferrer noopener'
          >
            <LogoOmen
              className={cn({
                icon: true,
                iconOmen: true,
              })}
          />
          </a>
          <a
            className={styles.logo}
            href="http://bit.ly/2Zv8gMC"
            target="_blank"
            rel='noreferrer noopener'
          >
            <LogoMicrosoft
              className={cn({
                icon: true,
                iconMicrosoft: true,
              })}
            />
          </a>
        </div>
        <div className={styles.right}>
          <button
            className={styles.button}
            onClick={onOpen}
          >
            <span className={styles.buttonInner}>
              <IconMenu />
            </span>
            <StripeGenerator
              space={10}
              color={isSticky ? '#ea002a' : '#000'}
              className={styles.stripe}
            />
          </button>
        </div>
      </header>
    );
  }
}


export default MobileHeader;
