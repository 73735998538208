import React, { Component } from 'react';
import Type from 'prop-types'
import { ReactComponent as IconCheck } from './check.svg';
import classNames from 'classnames/bind';
import styles from './Checkbox.module.scss';

const cn = classNames.bind(styles);

class Checkbox extends Component {
  static propTypes = {
    text: Type.node,
    id: Type.string,
    name: Type.string,
    value: Type.string,
    size: Type.oneOf(['s', 'm', 'l']),
    checked: Type.bool,
    disabled: Type.bool,
    required: Type.bool,
    indeterminate: Type.bool,
    className: Type.string,
    color: Type.string,
    backgroundColor: Type.string,
    onChange: Type.func,
  };

  static defaultProps = {
    size: 'm',
    color: '#fff',
    backgroundColor: '#000',
  };

  state = {
    checked: false,
  };

  getValue() {
    return this.props.checked !== undefined ?
      this.props.checked : this.state.checked;
  }

  handleChange = (e) => {
    const { name, onChange } = this.props;
    const nextCheckedValue = !this.getValue();

    this.setState({ checked: nextCheckedValue });

    if (onChange) {
      onChange({ name, value: nextCheckedValue });
    }
  }

  render() {
    const {
      text,
      id,
      name,
      value,
      size,
      disabled,
      required,
      indeterminate,
      color,
      backgroundColor,
      onChange,
      ...restProps
    } = this.props;

    const checked = this.getValue();

    const labelProps = {
      ...restProps,
      className: cn({
        checkbox: true,
        [`size_${size}`]: size,
        checked,
        disabled,
        indeterminate: !checked && indeterminate,
      }),
    };

    const checkboxProps = {
      id,
      name,
      value,
      checked,
      disabled,
    };

    return (
      <label
        htmlFor={id}
        style={{'color': color}}
        {...labelProps}
      >
        <span
          className={styles.box}
          key="box"
          style={{
            backgroundColor,
          }}
        >
          <input
            className={styles.control}
            type="checkbox"
            autoComplete="off"
            onChange={this.handleChange}
            {...checkboxProps}
          />
          <IconCheck
            className={styles.icon}
            size={size}
          />
        </span>
        {
          text &&
          <span
            className={styles.text}
            key="text"
            role="presentation"
          >
            {text}

            {required &&
              <span
                aria-hidden="true"
                className={styles.requiredMarker}
              >
                 *
              </span>
            }
          </span>
        }
      </label>
    );
  }
}

export default Checkbox;
