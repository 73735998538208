import React, { Component } from 'react';
import styles from './Game.module.scss';
import { Game as GameAPI} from '../../api';

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      game: null,
      error: null
    }
    this.wrapper = React.createRef();
  }

  async componentDidMount() {
    this.props.onSetHomePage(false);

    try {
      const game = await GameAPI.start();
      this.setState({
        game: game.data.game_iframe,
      });

      const frame = this.wrapper.current.querySelector('iframe');
      if (frame) {
        frame.setAttribute('allowFullScreen', 'allowFullScreen')
      };

    } catch (error) {
      this.setState({error})
    }

  }

  render() {
    if (this.state.error){
      throw this.state.error
    }

    const { game } = this.state;

    return (
      <div
        ref={this.wrapper}
        className={styles.game}
        dangerouslySetInnerHTML={{__html: game}}
      />
    );
  }
}

export default Game;
