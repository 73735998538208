import React, { Component } from 'react';
import Type from 'prop-types'
import { withRouter } from 'react-router-dom'
import clamp from 'lodash/clamp';

class ScrollOffsetBox extends Component {
  ticking = false;

  static propTypes = {
    offset: Type.number,
    isOffsetByX: Type.bool,
    reverse: Type.bool,
    disable: Type.bool,
  };

  static defaultProps = {
    disable: false,
    factor: 0.5,
    initialOffset: 0,
    reverse: true,
    types: ['y'],
  };

  state = {
    transition: 0,
    initialTop: 0,
  };

  constructor(props) {
    super(props);

    this.elementRef = React.createRef();
  }

  componentDidMount() {
    const { additionalElement, disable } = this.props;

    if (!disable) {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll, { passive: true });
      window.addEventListener('resize', this.handleScroll, { passive: true });

      if (additionalElement) {
        additionalElement.addEventListener('scroll', this.handleScroll, { passive: true });
      }
    }
  }

  componentWillUnmount() {
    const { additionalElement, disable } = this.props;

    if (!disable) {
      window.removeEventListener('scroll', this.handleScroll, { passive: true });
      window.removeEventListener('resize', this.handleScroll, { passive: true });

      if (additionalElement) {
        additionalElement.removeEventListener('scroll', this.handleScroll, { passive: true });
      }
    }
  }

  update = () => {
    const { disable, initialOffset } = this.props;

    if (!disable) {
      const element = this.elementRef.current;
      if (!element) return;
      const rect = element.getBoundingClientRect();

      let offset = -rect.top + window.innerHeight - initialOffset - this.props.offset;
      offset *= this.props.factor;
      offset = clamp(offset, 0, this.props.offset);
      this.setState({ transition: offset });
      this.ticking = false;
    }
  }

  handleScroll = () => {
    this.requestTick();
  };

  requestTick() {
    if (!this.ticking) {
      requestAnimationFrame(this.update);
      this.ticking = true;
    }
  }

  render() {
    const { disable, offset, reverse, types } = this.props;
    const style = {};
    let currentPercent;

    if (!disable) {
      if (types.includes('y')) {
        const translate = reverse ? offset - this.state.transition : this.state.transition;
        style['transform'] = `translate(0, ${translate}px)`;
        style['willChange'] = 'transform';
      }

      if (types.includes('x')) {
        const translate = reverse ? offset - this.state.transition : this.state.transition;
        style['transform'] = `translate(${translate}px, 0)`;
        style['willChange'] = 'transform';
      }

      if (types.includes('fade')) {
        style['opacity'] = 1 - (this.props.offset - this.state.transition) / this.props.offset;
        style['willChange'] = 'opacity';
      }

      if (types.includes('scale')) {
        const scale = 1 - (this.props.offset - this.state.transition) / this.props.offset;

        style['transform'] = `scale(${scale}, 1)`;
        style['transformOrigin'] = 'top left';
        style['transition'] = 'transform .35s ease-in-out'
        style['willChange'] = 'transform';
      }

      if (types.includes('counter')) {
        currentPercent = 100 - Math.ceil(((this.props.offset - this.state.transition) / this.props.offset) * 100);
      }
    }

    return disable ?
      (
        <>
          {this.props.children}
        </>
      ) : (
      <div
        ref={this.elementRef}
        className={this.props.className}
        style={this.props.style}
      >
        <div style={style}>
          { React.Children.map(this.props.children, (child) => {
              if (typeof child.type === 'function') {
                return React.cloneElement(child, {currentPercent})
              } else {
                return child
              }
            })
          }
        </div>
      </div>
    );
  }
}

export default withRouter(ScrollOffsetBox);
