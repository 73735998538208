import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import styles from './Input.module.scss';

const cn = classNames.bind(styles);

class Input extends Component {
  static propTypes = {
    name: Type.string,
    type: Type.string,
    value: Type.string,
    defaultValue: Type.string,
    placeholder: Type.string,
    autocomplete: Type.oneOfType([Type.bool, Type.string]),
    fullWidth: Type.bool,
    hasLabel: Type.bool,
    showArrow: Type.bool,
    isReadonly: Type.bool,
    iconLeft: Type.object,
    iconRight: Type.object,
    onClick: Type.func,
    onChange: Type.func,
  };

  static defaultProps = {
    hasLabel: true,
    type: 'text',
  };

  state = {
    value: this.props.defaultValue || ''
  };

  handleChange = (e) => {
    this.changeValue(e.target.value);
  }

  getAutoCompleteValue() {
    const { autocomplete } = this.props;

    if (typeof autocomplete === 'string') {
      return autocomplete;
    }

    return autocomplete === false ? 'off' : 'on';
  }

  changeValue(value) {
    const { name, onChange } = this.props;

    if (this.props.value === undefined) {
      this.setState({ value });
    }

    if (onChange) {
      onChange({ name, value });
    }
  }

  renderIcon(Icon, className) {
    return (
      <span className={cn({
        icon: true,
        [className]: true,
      })}>
        <Icon />
      </span>
    );
  }

  render() {
    const {
      name,
      type,
      placeholder,
      hasLabel,
      fullWidth,
      isReadonly,
      iconLeft,
      iconRight,
      onClick,
    } = this.props;

    const mods = {
      input: true,
      fullWidth,
      isReadonly,
    };

    const value = this.props.value !== undefined
        ? this.props.value
        : this.state.value;

    return (
      <span
        className={cn(mods)}
      >
        {hasLabel &&
          <span className={styles.label}>{placeholder}</span>
        }

        <span className={styles.box}>
          {iconLeft && this.renderIcon(iconLeft, styles.iconLeft)}

          <input
            name={name}
            type={type}
            autoComplete={this.getAutoCompleteValue()}
            className={styles.control}
            value={value}
            placeholder={placeholder}
            readOnly={isReadonly}
            onClick={onClick}
            onChange={this.handleChange}
          />

          {iconRight && this.renderIcon(iconRight, styles.iconRight)}
        </span>
      </span>
    );
  }
}

export default Input;
