import React, { Component } from 'react';
import Type from 'prop-types'
import { ReactComponent as IconArrow } from '../../../assets/images/svg/arrow.svg';
import { ReactComponent as Frame } from './frame.svg';
import styles from './ActionCard.module.scss';
import { AuthContext } from "../../../App";
import { withRouter } from 'react-router-dom'

class ActionCard extends Component {
  static propTypes = {
    title: Type.string,
    actionButtonText: Type.string,
  };

  handleClick(event, isAuthorized, handleOpen ){
    if (isAuthorized) {
      this.props.history.push('/');
    } else {
      event.preventDefault()
      handleOpen()
    }
  }

  render() {
    const { title, actionButtonText } = this.props;

    return (
      <AuthContext.Consumer>
        {({ isAuthorized, handleOpen }) => (
          <a href={'/#tasks-section'}
            className={styles.card}
            onClick={(event) => this.handleClick(event, isAuthorized, handleOpen)}
          >
            <Frame className={styles.frame} />

            <div className={styles.wrapper}>
              <div className={styles.cover}>
                <div className={styles.image}></div>
              </div>
              <div className={styles.body}>
                <div className={styles.container}>
                  <div className={styles.content}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.button}>{actionButtonText}</div>
                  </div>
                  <div className={styles.extra}>
                    <IconArrow className={styles.icon} />
                  </div>
                </div>
              </div>
            </div>
          </a>
        )}
      </AuthContext.Consumer>

    );
  }
}

export default withRouter(ActionCard);
