import { request } from './instance';
import { orders, historyOLP } from './mockedAPI';
// https://omen.green.jetstyle.ru/api/v1/player_profile/
const SHOP_API_URL = process.env.REACT_APP_SHOP_API_URL;

const Shop = {
  getProfile(params) {
    return request({
      url: 'player_profile/',
      baseURL: SHOP_API_URL,
      params,
      canApplyToken: true,
    });
  },
  getProducts(params) {
    return request({
      url: 'products/',
      baseURL: SHOP_API_URL,
      params,
    });
  },
  postOrder(params) {
    return request({
      url: 'orders/',
      method: 'POST',
      baseURL: SHOP_API_URL,
      data: params,
      canApplyToken: true,
    });
  },
  getOrders(params) {
    // return request({
    //   url: 'orders/3',
    //   method: 'GET',
    //   baseURL: SHOP_API_URL,
    //   canApplyToken: true,
    //   params,
    // });
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(orders)
      }, 1000)
    })
  },
  getHistoryOLP(params) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(historyOLP)
      }, 1000)
    })
  },
};

export default Shop;
