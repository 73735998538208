import React, { Component } from 'react';
import Type from 'prop-types'
import ClassNames from 'classnames/bind';
import styles from './FileLoader.module.scss';
import { Spinner } from '../../components';
const cn = ClassNames.bind(styles)

export default class FileLoader extends Component {
  static propTypes = {
    handler: Type.func,
    pending: Type.bool,
    accept: Type.string,
    placeholder: Type.string
  }

  render() {
    const { pending, placeholder, handler } = this.props;
    return (
      <label className={cn({root: true, pending })}>
        <div className={styles.name}>
          <span>{placeholder}</span>
        </div>
        <div className={styles.btn}>
          {pending && <Spinner cName={styles.loader} size="38px" />}
          <input type="file" accept={this.props.accept} disabled={pending} onChange={handler} />
        </div>
      </label>
    )
  }
}
