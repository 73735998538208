import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import { StripeGenerator } from '../../../components';
import styles from './Button.module.scss';

const cn = classNames.bind(styles);

class Button extends Component {
  static propTypes = {
    text: Type.node,
    icon: Type.node,
    name: Type.string,
    className: Type.string,
    iconPosition: Type.oneOf(['left', 'right']),
    type: Type.oneOf(['button', 'reset', 'submit']),
    size: Type.oneOf(['xs', 's', 'm', 'l']),
    slide: Type.oneOf(['red', 'black']),
    href: Type.string,
    color: Type.string,
    borderColor: Type.string,
    fingerPrintColor: Type.string,
    fingerPrintFillColor: Type.string,
    fullWidth: Type.bool,
    disabled: Type.bool,
    loading: Type.bool,
    dashed: Type.bool,
    semiFilled: Type.bool,
    stripeProps: Type.object,
    withFingerPrint: Type.bool,
    children: Type.oneOfType([Type.arrayOf(Type.node), Type.node]),
    onClick: Type.func,
  };

  static defaultProps = {
    type: 'button',
    size: 'm',
    slide: 'black',
    iconPosition: 'left',
    loading: false,
    color: '#fff',
    borderColor: '#fff',
    fingerPrintColor: '#fff',
    fingerPrintFillColor: 'transparent',
  };

  getNode() {
    return this.control;
  }

  control;

  handleClick = (e) => {
    const { loading, onClick } = this.props;

    if (onClick && !loading) {
      onClick(e);
    }
  }

  render() {
    const {
      children,
      name,
      type,
      icon,
      iconPosition,
      text,
      disabled,
      color,
      borderColor,
      fingerPrintColor,
      fingerPrintFillColor,
      size,
      slide,
      fullWidth,
      onClick,
      loading,
      href,
      withFingerPrint,
      dashed,
      stripeProps,
      semiFilled,
      ...restProps
    } = this.props;

    const mods = {
      button: true,
      disabled: disabled,
      [`size_${size}`]: size,
      [`slide_${slide}`]: slide,
      fullWidth: fullWidth,
      loading: loading,
      withFingerPrint,
      dashed,
      semiFilled,
      [`iconPosition_${iconPosition}`]: iconPosition,
    };

    const buttonProps = {
      ...restProps,
      ref: (control) => { this.control = control; },
      role: 'button',
      name,
      type,
      disabled,
      className: cn([mods]),
    };

    const linkProps = {
      ...restProps,
      href,
      ref: (control) => { this.control = control; },
      className: cn(mods)
    };

    const content = children || text;
    const hasContent = typeof content !== 'undefined';

    const iconTemplate = (
      icon &&
        <span key="icon" className={styles.icon}>
          {icon}
        </span>
    );

    const dashedTemplate = (
      dashed &&
        <span
          key="dashed"
          className={styles.stripe}
          style={{
            ...stripeProps
          }}
        >
          <StripeGenerator
            space={6}
            color={borderColor}
          />
        </span>
    );

    const buttonFingerPrint = (
      withFingerPrint &&
        <span
          key="fingerPrint"
          className={styles.fingerPrint}
          style={{
            backgroundColor: fingerPrintFillColor,
            borderColor,
          }}
        >
          <StripeGenerator
            space={5}
            color={fingerPrintColor}
            className={styles.leftStripe}
          />
          <StripeGenerator
            space={5}
            color={fingerPrintColor}
            className={styles.rightStripe}
          />
        </span>
    )

    const textTemplate = (
      hasContent &&
        <span key="text" className={styles.text}>
          {content}
        </span>
    );
    const buttonContent = [
      iconPosition === 'left' && iconTemplate,
      textTemplate,
      buttonFingerPrint,
      dashedTemplate,
      iconPosition === 'right' && iconTemplate
    ];

    const TagName = href !== undefined ? 'a' : 'button';
    const props = href !== undefined ? linkProps : buttonProps;

    return (
      <TagName
        {...props}
        style={{
          color,
          borderColor,
        }}
        onClick={this.handleClick}
      >
        {loading &&
          <span className={styles.loading} />
        }

        {buttonContent}
      </TagName>
    );
  }
}

export default Button;
