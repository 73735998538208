import React, { Component } from 'react';
import { Checkbox } from '../../../components';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import styles from './TaskCard.module.scss';
import { AuthContext } from "../../../App";

const cn = classNames.bind(styles);

class TaskCard extends Component {
  static propTypes = {
    data: Type.shape({
      id: Type.number,
      description: Type.string,
      image: Type.string,
      points_OLP: Type.number,
      joined_task: Type.number,
    }).isRequired,
    isMobile: Type.bool,
    onClick: Type.func,
  };

  state = {
    completed: false
  }

  handleClick = ({ target }, isAuthorized, handleOpen) => {
    const { isMobile, onClick } = this.props;
    const { completed } = this.state;

    if (isMobile && onClick) {
      onClick(target.id);
    } else {
      if (isAuthorized) {
        if (onClick && !completed) {
          onClick(target.id);

          this.setState({completed: true})
        }
      } else {
        handleOpen()
      }
    }
  }

  componentDidMount() {
    const { joined_task } = this.props.data;
    this.setState({completed: Boolean(joined_task)});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data.joined_task !== this.props.data.joined_task) {
      const { joined_task } = this.props.data;
      this.setState({completed: Boolean(joined_task)});
    }
  }

  render() {
    const { id, description, points_OLP, image } = this.props.data;
    const { completed } = this.state
    const cardType = completed ? 'completed' : 'incompleted';
    const imageUrl = image;

    const mods = {
      card: true,
      [`type_${cardType}`]: cardType,
    };

    return (
      <AuthContext.Consumer>
        {({ isAuthorized, handleOpen }) => (
          <div className={cn(mods)}>
            {completed &&
            <span className={styles.checkbox}>
              <Checkbox size="l" checked/>
            </span>
            }
            <div className={styles.cover}>
              <div
                className={styles.image}
                style={{backgroundImage: `url(${imageUrl})`}}
              >
              </div>
            </div>
            <div className={styles.body}>
              <div className={styles.container}>
                <div className={styles.wrapper}>
                  <div className={styles.content}>
                    <div className={styles.box}>
                      <div className={styles.text}>
                        <div className={styles.title}>
                          Цель
                        </div>
                        <div className={styles.description}>
                          {description}
                        </div>
                      </div>
                      <span
                        className={cn({
                          button: true,
                          buttonFull: completed
                        })}
                        id={id}
                        onClick={(event) => this.handleClick(event, isAuthorized, handleOpen)}
                      >
                        {completed ? 'Участвую' : 'Участвовать'}
                      </span>
                    </div>
                    <div className={styles.box}>
                      <span className={styles.title}>
                        Olp
                      </span>
                      <span className={styles.points}>
                        {points_OLP}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AuthContext.Consumer>
    )
  }
}

export default TaskCard;
