export const slides = [
  {
    description: 'Точные движения, точные и слаженные действия в команде. Добил, ультанул — победил. Зарабатывай OLP прокачиваясь.Играй лучше, чем вчера!',
  },
  {
    description: 'Скорость принятия решений, скорость атаки, скорость движения. Скорость, с которой растут твои навыки. Где баланс? Качай свои игровые скиллы на платформе и зарабатывай OLP. Играй лучше, чем вчера!',
  },
  {
    description: 'Учись контролю. Контролю себя и игры. Развивай навыки на платформе.  Играй лучше, чем вчера!',
  },
  {
    description: 'Каждая секунда на счету. Каждый добитый крип. Каждый вовремя прожатый скилл приближает тебя к победе. Твоя реакция — это твоя заявка на победу. Играй лучше, чем вчера!'
  },
];
