import React, { Component } from 'react';
import styles from './Shop.module.scss';
import classNames from 'classnames/bind';
import { ShopCard } from '../../components';
const cn = classNames.bind(styles);

class Shop extends Component {

  handleClick = productId => {
    this.props.onAddtoCart(productId);
    window.dataLayer && window.dataLayer.push({
      'event': "uEvent",
      "eventCategory": "Shop",
      "eventAction": "AddItem"
    });
  }

  openCard = () => {
    this.props.onOpenCart()
  }

  componentDidMount() {
    this.props.onSetHomePage(false);
    this.props.onGetProducts();
    this.props.getUserInfo();
    window.scrollTo(0, 0);
  }

  renderCards = () => {
    const { products, selectedProducts } = this.props;
    return products.map(product => (
      <div className={styles.listItem} key={product.id}>
        <ShopCard
          name={product.name}
          id={product.id}
          handleClick={this.handleClick}
          isDisabled={product.amount === 0}
          isSelected={selectedProducts.includes(product.id)}
          price={product.price}
          stock={product.amount}
          limit={product.total_amount}
          image={product.image} />
      </div>
    ));
  }

  render() {
    const { selectedProducts, cartCount } = this.props;
    return (
      <div className={styles.root}>
        <div className={cn({
          cartHandler: true,
          isVisible: selectedProducts.length
        })}>
          <button
            title="Открыть корзину"
            type="button"
            onClick={this.openCard}>
            <span>+{cartCount}</span>
          </button>
        </div>
        <div className={styles.header}>
          <h1 className={styles.title}>Магазин OLP</h1>
          <div className={styles.text}>Здесь ты&nbsp;можешь обменять заработанные OLP на&nbsp;мерч, билеты и&nbsp;многое другое&nbsp;&mdash; следи за&nbsp;обновлениями магазина! Статус покупок будет отображаться в&nbsp;личном кабинете.</div>
          <div className={cn({text: true, danger: true})}>Выбор за тобой!</div>
        </div>
        <div className={styles.listWrapper}>
          <div className={styles.list}>{ this.renderCards() }</div>
        </div>
      </div>
    );
  }
}

export default Shop;
