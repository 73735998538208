import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import Type from 'prop-types'
import classNames from 'classnames/bind';
import { StripeGenerator } from '../../components';
import { menuItems } from '../Header/menu-items';
import { ReactComponent as IconClose } from '../../assets/images/svg/close.svg';
import styles from './MobileMenu.module.scss';
import { AuthContext } from "../../App";

const cn = classNames.bind(styles);

class MobileMenu extends Component {
  static propTypes = {
    isOpen: Type.bool,
    isHomePage: Type.bool,
    onClose: Type.func.isRequired,
    openSignIn: Type.func.isRequired
  };

  state = {
    activeMenuItem: ''
  };

  handleClick = ({ target }) => {
    const activeMenuItem = target.hash.substr(1);

    const { onClose } = this.props;

    this.setState({ activeMenuItem });

    if (onClose) {
      onClose();
    }
  }

  navigateTo = (route) => {
    const { onClose } = this.props;
    this.props.history.push(route);
    if (onClose) {
      onClose();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentPath = this.props.history.location.hash.substr(1);

    if (currentPath !== this.state.activeMenuItem) {
      this.setState({ activeMenuItem: currentPath });
    }
  }

  link(args) {
    console.log(args)
    const { isHomePage } = this.props;
    const { activeMenuItem } = this.state;
    const route = args.route;
    const anchor = args.anchor;
    const title = args.title;
    const isActive = activeMenuItem !== '' && activeMenuItem === anchor;

    return (
      <span key={anchor} className={styles.item}>
        <a
          href={`#${anchor}`}
          className={cn({
            link: true,
            active: isActive,
          })}
          onClick={this.handleClick}
        >
          {title}
        </a>
      </span>
    )
  }

  render() {
    const { isOpen, onClose, onSignOut, openSignIn } = this.props;
    return <AuthContext.Consumer>
      {({ isAuthorized }) => (
        <div
          className={cn({
            menu: true,
            open: isOpen,
          })}
        >
          <div className={styles.header}>
            <StripeGenerator
              space={10}
              color="#ea002a"
              className={cn({
                stripe: true,
                stripe1: true,
              })}
            />
            <button
              className={styles.close}
              onClick={onClose}
            >
              <IconClose className={styles.iconClose} />
            </button>
          </div>

          <div className={styles.content}>
            <StripeGenerator
              space={10}
              color="#ea002a"
              className={cn({
                stripe: true,
                stripe2: true,
              })}
            />

            <nav className={styles.nav}>
              {
                isAuthorized
                  ? <span className={styles.item}>
                    <button className={cn({ button: true })} onClick={onSignOut}>
                        Выход
                      </button>
                    </span>

                  : <span className={styles.item}>
                      <button
                        className={cn({ button: true })}
                        onClick={() => { onClose(); openSignIn() }}
                        >Вход</button>
                    </span>
              }

              <span className={styles.item}>
                <Link
                  to="/dota2"
                  className={cn({
                    link: true,
                  })}
                  onClick={this.handleClick}
                >dota2</Link>
              </span>

              <span className={styles.item}>
                <Link
                  to="/csgo"
                  className={cn({
                    link: true,
                  })}
                  onClick={this.handleClick}
                >CS:GO</Link>
              </span>

              {
                ['/csgo', '/dota2'].includes(this.props.location.pathname) &&
                menuItems.map(menuItem => this.link({ ...menuItem }))
              }

              {
                isAuthorized &&
                <span className={styles.item}>
                  <Link key="account-link" to="/account" onClick={() => this.navigateTo('/account')} className={styles.link}>Личный кабинет</Link>
                </span>
              }
            </nav>
            <div className={styles.bg}></div>
          </div>
        </div>
      )}
    </AuthContext.Consumer>
  }
}


export default withRouter(MobileMenu);
