import React, { Component } from 'react';
import Type from 'prop-types'
import { Link, NavLink } from 'react-router-dom'
import { Link as ScrollLink } from "react-scroll";
import classNames from 'classnames/bind';
import { AuthContext } from "../../App";
import { menuItems } from './menu-items';
import { StripeGenerator } from '../../components';
import { ReactComponent as LogoOmen } from '../../assets/images/svg/logos/omen.svg';
import { ReactComponent as LogoMicrosoft } from '../../assets/images/svg/logos/microsoft.svg';
import { ReactComponent as IconSteam } from '../../assets/images/svg/logos/steam.svg';
import styles from './Header.module.scss';

const cn = classNames.bind(styles);
const SCROLL_THRESHOLD = '20';

class Header extends Component {
  rafExecuting = false;

  static propTypes = {
    isHomePage: Type.bool,
    onOpen: Type.func.isRequired,
    onSignOut: Type.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSticky: false,
    };

    this.underlineRef = React.createRef();
  }

  handleScroll = () => {
    if (this.rafExecuting) {
      return;
    }

    this.rafExecuting = true;
    const { isSticky } = this.state;
    const pastScrollThreshold = window.pageYOffset > SCROLL_THRESHOLD;

    requestAnimationFrame(() => {
      if (pastScrollThreshold && !isSticky) {
        this.setState({ isSticky: true });
      } else if (!pastScrollThreshold && isSticky) {
        this.setState({ isSticky: false });
      }

      this.rafExecuting = false;
    });
  }

  link(args) {
    const anchor = args.anchor;
    const title = args.title;
    const offset = args.offset ? args.offset : 0;
    return (
      <ScrollLink
        key={anchor}
        className={styles.link}
        spy={true}
        smooth={true}
        duration={500}
        to={anchor}
        offset={offset}
      >
        {title}
      </ScrollLink>
    )
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleSetActiveMenu = () => {
    const element = this.underlineRef.current;
    const to = document.querySelector(`.${styles.active}`);

    if (element && to) {
      element.style.display = 'block';
      element.style.left = `${to.offsetLeft}px`;
      element.style.width = `${to.clientWidth}px`;
    } else if (element) {
      element.style.display = 'none';
    }
  }

  render() {
    const { onOpen, onSignOut, isHomePage } = this.props;
    const { isSticky } = this.state;

    return (
      <AuthContext.Consumer>
        {({ isAuthorized }) => (
          <header
            className={cn({
              header: true,
              isSticky,
            })}
          >
            <div className={styles.fill} />
            <div className={styles.left}>
              <a
                className={styles.logo}
                href="http://bit.ly/2Gv7mXo"
                target="_blank"
                rel='noreferrer noopener'
              >
                <LogoOmen
                  className={cn({
                    icon: true,
                    iconOmen: true,
                  })}
              />
              </a>
              <a
                className={styles.logo}
                href="http://bit.ly/2Zv8gMC"
                target="_blank"
                rel='noreferrer noopener'
              >
                <LogoMicrosoft
                  className={cn({
                    icon: true,
                    iconMicrosoft: true,
                  })}
                />
              </a>
            </div>

            {!isHomePage &&
              <div className={styles.right}>
                <nav className={styles.nav}>
                  <div
                    ref={this.underlineRef}
                    className={styles.underline}
                  />
                  <div className={styles.gameNav}>
                    <NavLink to="/dota2" className={styles.gameLink} activeClassName={styles.gameLinkActive}>
                      <span>DOTA2</span>
                    </NavLink>
                    <span className={styles.gameLinkSeparator}>/</span>
                    <NavLink to="/csgo" className={styles.gameLink} activeClassName={styles.gameLinkActive}>
                      <span>CS:GO</span>
                    </NavLink>
                  </div>
                  {
                    ['/csgo', '/dota2'].includes(this.props.location.pathname) &&
                    menuItems.map(menuItem => this.link({ ...menuItem }))
                  }
                  {
                    isAuthorized && 
                    <Link key="account-link" to="/account" className={styles.link}>Личный кабинет</Link>
                  }
                </nav>
                {
                  isAuthorized
                    ? (
                      <button
                        className={cn({button: true, patternButton: true})}
                        onClick={onSignOut}
                      >
                        <span>Выход</span>
                      
                        <StripeGenerator
                          space={10}
                          color="#ea002a"
                          strokeWidth={2}
                          className={styles.stripe}
                        />
                      </button>
                    )
                    : (
                      <button
                        className={styles.button}
                        onClick={onOpen}
                          >
                          <IconSteam
                            className={cn({
                              icon: true,
                              iconSteam: true,
                            })}
                          />
                          Вход
                        </button>
                      )
                  }

              </div>
            }
          </header>
        )}
      </AuthContext.Consumer>
    );
  }
}


export default Header;
