import React, { Component } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import classNames from 'classnames/bind';
import { Tween, Timeline } from 'react-gsap';
import { StripeGenerator, Button, Graphic } from '../../components';
import bg1 from './bg-1.png';
import bg2 from './bg-2.png';
import bg3 from './bg-3.png';
import bg4 from './bg-4.png';
import bg5 from './bg-5.png';
import bg6 from './bg-6.png';
import img1 from './img-1.png';
import img2 from './img-2.png';
import img3 from './img-3.png';
import img4 from './img-4.png';
import img5 from './img-5.png';
import img6 from './img-6.png';
import styles from './Mechanics.module.scss';
import { AuthContext } from "../../App";
import { withRouter } from 'react-router-dom'

const cn = classNames.bind(styles);

class Mechanics extends Component {
  handleClick(event, isAuthorized, handleOpen) {
    if (isAuthorized) {
      this.props.history.push('/#tasks-section')
    } else {
      event.preventDefault()
      handleOpen()
    }
  }

  componentDidMount() {
    this.props.onSetHomePage(false);
  }

  render() {
    return (
      <AuthContext.Consumer>
        {({ isAuthorized, handleOpen })=> (
          <div className={styles.wrapper}>
            <section className={styles.sectionIntro}>
              <div className={styles.textBlock}>
                <h1 className={styles.title}>Играй<br />лучше, чем вчера<br /> с OMEN by HP</h1>
                <p className={styles.description}>Совершенствуйся в&nbsp;Dota&nbsp;2 и CS:GO&nbsp;— тренируйся, выполняй ежедневные задания, покупай технику OMEN by&nbsp;HP, собирай команду. Играй на&nbsp;своем максимуме.</p>
              </div>
              <div className={styles.bg}></div>
              <div className={styles.fg}></div>
            </section>

            <section className={styles.slider}>
              <div className={styles.sliderWrapper}>
                <div className={styles.slide1}>
                  <div className={styles.slideLeft}>
                    <Controller>
                      <Scene
                        duration={6440}
                        offset={-70} // pin img excluding header height
                        triggerHook={0}
                        pin
                      >
                          {(progress) => (
                            <div>
                              <Timeline totalProgress={progress} paused>
                                <Tween
                                  from={{ opacity: 0 }}
                                  to={{ opacity: 1 }}
                                  duration={0.9}
                                >
                                  <img
                                    src={bg1}
                                    className={styles.bg1}
                                    alt="bg1"
                                  />
                                </Tween>

                                <Timeline
                                  target={
                                    <img
                                      src={bg2}
                                      className={styles.bg2}
                                      alt="bg2"
                                    />
                                  }
                                >
                                  <Tween
                                    from={{ opacity: 0 }}
                                    to={{ opacity: 1 }}
                                  />
                                </Timeline>

                                <Timeline
                                  target={
                                    <img
                                      src={bg3}
                                      className={styles.bg2}
                                      alt="bg3"
                                    />
                                  }
                                >
                                  <Tween
                                    from={{ opacity: 0 }}
                                    to={{ opacity: 1 }}
                                  />
                                </Timeline>

                                <Timeline
                                  target={
                                    <img
                                      src={bg4}
                                      className={styles.bg2}
                                      alt="bg4"
                                    />
                                  }
                                >
                                  <Tween
                                    from={{ opacity: 0 }}
                                    to={{ opacity: 1 }}
                                  />
                                </Timeline>

                                <Timeline
                                  target={
                                    <img
                                      src={bg5}
                                      className={styles.bg2}
                                      alt="bg5"
                                    />
                                  }
                                >
                                  <Tween
                                    from={{ opacity: 0 }}
                                    to={{ opacity: 1 }}
                                  />
                                </Timeline>

                                <Timeline
                                  target={
                                    <img
                                      src={bg6}
                                      className={styles.bg2}
                                      alt="bg6"
                                    />
                                  }
                                >
                                  <Tween
                                    from={{ opacity: 0 }}
                                    to={{ opacity: 1 }}
                                  />
                                </Timeline>
                              </Timeline>
                            </div>
                          )}
                      </Scene>
                    </Controller>
                  </div>

                  <div className={styles.slideRight}>
                    <Controller>
                      <Scene
                        duration={600}
                        triggerHook={0.15}
                        pin
                      >
                        {(progress, event) => (
                          <div className={styles.content}>
                            <Timeline totalProgress={progress} paused>
                              <Tween
                                from={{ opacity: 0, top: '20%' }}
                                to={{ opacity: 1, top: '0%' }}
                                duration={0.3}
                              >
                                <h2
                                  className={cn({
                                    slideTitle: true,
                                    sticky: true,
                                  })}
                                >
                                  Что такое OLP?
                                </h2>
                              </Tween>


                              <Timeline
                                target={
                                  <p
                                    className={cn({
                                      slideDescription: true,
                                      sticky: true,
                                    })}
                                  >
                                    Omen loyalty points (OLP)&nbsp;— это виртуальная валюта OMEN by&nbsp;HP. Чем лучше ты играешь в&nbsp;Dota&nbsp;2 и CS:GO, чем чаще тренируешься, тем больше OLP ты зарабатываешь. За&nbsp;OLP можно купить крутой мерч в&nbsp;магазине на&nbsp;сайте.
                                  </p>
                                  }
                                >
                                  <Tween
                                    from={{ opacity: 0, top: '40%' }}
                                    to={{ opacity: 1, top: '20%' }}
                                    duration={0.3}
                                  />
                              </Timeline>

                              <Timeline
                                target={
                                  <div className={styles.graphic}>
                                    <Graphic
                                      progress={progress}
                                    />
                                  </div>
                                }
                              >
                                <Tween
                                  from={{ opacity: 0, top: '80%' }}
                                  to={{ opacity: 1, top: '60%' }}
                                />
                              </Timeline>
                            </Timeline>
                          </div>
                        )}
                      </Scene>

                      <Scene
                        offset={400}
                        duration={300}
                        triggerHook={0.5}
                        pin
                      >
                        {(progress) => (
                          <div className={styles.content}>
                            <Timeline totalProgress={progress} paused>
                              <Tween
                                from={{ opacity: 0, top: '20%' }}
                                to={{ opacity: 1, top: '0' }}
                              >
                                <h2
                                  className={cn({
                                    slideTitle: true,
                                    sticky: true,
                                  })}
                                >
                                  Как зарабатывать OLP?
                                </h2>
                              </Tween>

                              <Timeline
                                target={
                                  <div className={styles.figure}>
                                    <p className={styles.slideDescription}>
                                      Выполняй ежедневные задания в&nbsp;Dota&nbsp;2 и CS:GO. Каждый день появляются новые. Выбирай, что сделать сегодня,&nbsp;— перебить 1000 крипов или подобрать 20 баунти рун? Система автоматически начислит тебе OLP, если ты выполнишь задание. OLP начисляются в&nbsp;день окончания задания.
                                    </p>
                                    <img src={img1} className={styles.screenshot} alt="img1" />
                                  </div>
                                  }
                                >
                                  <Tween
                                    from={{ opacity: 0, top: '50%' }}
                                    to={{ opacity: 1, top: '35%' }}
                                  />
                              </Timeline>
                          </Timeline>
                        </div>
                      )}
                      </Scene>

                      <Scene
                        duration={80}
                        triggerHook={0.5}
                        pin
                      >
                        {(progress) => (
                          <div className={styles.contentMin}>
                            <Timeline totalProgress={progress} paused>
                              <Tween
                                from={{ opacity: 0, top: '100%' }}
                                to={{ opacity: 1, top: '0%' }}
                              >
                                <div className={styles.figure}>
                                  <p className={styles.slideDescription}>Тренируйся в&nbsp;мини-играх по&nbsp;мотивам Dota 2. Раз в&nbsp;сутки ты можешь заработать OLP в&nbsp;каждой из&nbsp;трёх игр.</p>
                                  <img src={img2} className={styles.screenshot} alt="img2" />
                                </div>
                              </Tween>
                            </Timeline>
                          </div>
                      )}
                      </Scene>

                      <Scene
                        duration={80}
                        triggerHook={0.5}
                        pin
                      >
                        {(progress) => (
                          <div className={styles.contentMin}>
                            <Timeline totalProgress={progress} paused>
                              <Tween
                                from={{ opacity: 0, top: '100%' }}
                                to={{ opacity: 1, top: '0%' }}
                              >
                                <div className={styles.figure}>
                                  <p className={styles.slideDescription}>
                                  Покупай технику OMEN&nbsp;by&nbsp;HP, загружай чек на&nbsp;сайт&nbsp;— если чек действителен, мы зачислим на&nbsp;твой счет OLP.
                                  </p>
                                  <img
                                    src={img3}
                                    className={styles.screenshot}
                                    alt="img3"
                                  />
                                </div>
                              </Tween>
                            </Timeline>
                          </div>
                      )}
                      </Scene>

                      <Scene
                        offset={400}
                        duration={300}
                        triggerHook={0.5}
                        pin
                      >
                        {(progress) => (
                          <div className={styles.content}>
                            <Timeline totalProgress={progress} paused>
                            <Tween
                                from={{ opacity: 0, top: '20%' }}
                                to={{ opacity: 1, top: '0' }}
                              >
                                <h2
                                  className={cn({
                                    slideTitle: true,
                                    sticky: true,
                                  })}
                                >
                                  Как потратить OLP?
                                </h2>
                              </Tween>

                              <Tween
                                from={{ opacity: 0, top: '50%' }}
                                to={{ opacity: 1, top: '25%' }}
                              >
                                <div className={styles.figure}>
                                  <p className={styles.slideDescription}>
                                  Заходи в магазин OLP&nbsp;— там ты можешь обменять валюту на&nbsp;мерч, билеты, разборы игр и многое другое. Ассортимент магазина будет пополняться. Количество товаров ограничено.
                                  </p>
                                  <img
                                    src={img4}
                                    className={styles.screenshot}
                                    alt="img4"
                                  />
                                </div>
                              </Tween>
                            </Timeline>
                          </div>
                      )}
                      </Scene>

                      <Scene
                        offset={400}
                        duration={300}
                        triggerHook={0.5}
                        pin
                      >
                        {(progress) => (
                          <div className={styles.content}>
                            <Timeline totalProgress={progress} paused>
                            <Tween
                                from={{ opacity: 0, top: '20%' }}
                                to={{ opacity: 1, top: '0' }}
                              >
                                <h2
                                  className={cn({
                                    slideTitle: true,
                                    sticky: true,
                                  })}
                                >
                                  Где посмотреть количество OLP на&nbsp;счету?
                                </h2>
                              </Tween>

                              <Tween
                                from={{ opacity: 0, top: '60%' }}
                                to={{ opacity: 1, top: '35%' }}
                              >
                                <div className={styles.figure}>
                                  <p className={styles.slideDescription}>
                                  Мы добавили личный кабинет, в&nbsp;котором ты найдешь список всех действий, за&nbsp;которые ты получил OLP. Там же&nbsp;— список покупок и их&nbsp;статус.
                                  </p>
                                  <img
                                    src={img5}
                                    className={styles.screenshot}
                                    alt="img5"
                                  />
                                </div>
                              </Tween>
                            </Timeline>
                          </div>
                      )}
                      </Scene>

                      <Scene
                        offset={400}
                        duration={300}
                        triggerHook={0.5}
                        pin
                      >
                        {(progress) => (
                          <div className={styles.content}>
                            <Timeline totalProgress={progress} paused>
                              <Tween
                                from={{ opacity: 0, top: '20%' }}
                                to={{ opacity: 1, top: '0' }}
                              >
                                <h2
                                  className={cn({
                                    slideTitle: true,
                                    sticky: true,
                                  })}
                                >
                                  Как работает рейтинг?
                                </h2>
                              </Tween>

                              <Tween
                                from={{ opacity: 0, top: '50%' }}
                                to={{ opacity: 1, top: '30%' }}
                              >
                                <div className={styles.figure}>
                                  <p className={styles.slideDescription}>
                                  Рейтинг выстраивается по&nbsp;количеству суммарно накопленных OLP игроками за&nbsp;все время. У&nbsp;нас есть отдельные рейтинги по&nbsp;Dota 2 и CS:GO и общий.
                                  </p>
                                  <img
                                    src={img6}
                                    className={styles.screenshot}
                                    alt="img6"
                                  />
                                </div>
                              </Tween>

                            </Timeline>
                          </div>
                        )}
                      </Scene>
                    </Controller>
                    <div className={styles.box}>
                      <div className={styles.boxWrapper}>
                        <div className={styles.boxCaption}>Присоединяйся к сообществу!</div>
                        <Button
                          text="Участвовать"
                          slide="red"
                          onClick={(e) => this.handleClick(e, isAuthorized, handleOpen)}
                          href="/#tasks-section"
                        />
                      </div>
                      <div className={styles.boxSquare}>
                        <StripeGenerator
                          space={14}
                          className={styles.boxPattern}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default withRouter(Mechanics);
