import React, { Component } from 'react';
import Type from 'prop-types'
import { StripeGenerator } from '../../../components';
import styles from './GameCard.module.scss';
import { AuthContext } from "../../../App";

class GameCard extends Component {
  static propTypes = {
    data: Type.shape({
      title: Type.string,
      description: Type.string,
      image: Type.string,
    }).isRequired,
    icon: Type.object.isRequired,
    onClick: Type.func.isRequired,
  };

  handleClick = () => {
    const { onClick } = this.props;

    if (onClick) {
      onClick();
    }
  }

  render() {
    const { icon: Icon } = this.props;
    const { description, title, image } = this.props.data;

    return (
      <AuthContext.Consumer>
        {({ isAuthorized }) => (
          <div className={styles.card}>
            <div className={styles.header}>
              <div className={styles.wrapper}>
                <div className={styles.box}>
                  <Icon className={styles.icon} />
                </div>
                <div className={styles.content}>
                  <div className={styles.title}>{title}</div>
                  <div className={styles.description}>{description}</div>
                </div>
              </div>
            </div>
            <div className={styles.frame}>
              <div className={styles.cover}>
                <div
                  className={styles.image}
                  style={{backgroundImage: `url(${image})`}}
                >
                </div>
              </div>
            </div>
            <div className={styles.footer} onClick={this.handleClick}>
              <div className={styles.button}>
                <div className={styles.buttonContent}>
              <span className={styles.buttonText}>
                Начать
              </span>
                  <StripeGenerator
                    space={6}
                    angle={45}
                    className={styles.stripe}
                    color="#000"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </AuthContext.Consumer>

    )
  }
}

export default GameCard;
