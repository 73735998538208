import { request } from './instance';

const Tasks = {
  getTasks(params) {
    return request({
      url: 'game_tasks/',
      canApplyToken: true,
      params
    });
  },
  joinTask(id) {
    return request({
      url: `player_join_game_task/${id}/`,
      method: 'POST',
      canApplyToken: true,
    });
  },
};

export default Tasks;
