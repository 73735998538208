import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Button, ProductCarousel, StripeGenerator } from '../../../components';
import { dataLayers } from '../../../utils/gtm';
import { Products } from '../../../api';
import { DeviceContext } from "../../../App";
import ScrollOffsetBox from "../../ScrollOffsetBox/ScrollOffsetBox";
import styles from './SectionProducts.module.scss';

const cn = classNames.bind(styles);

class SectionProducts extends Component {
  state = {
    products: [],
    error: null
  };

  handleClick = () => {
    window.dataLayer.push(dataLayers.knowMore);
    window.open('http://bit.ly/2Gv7mXo')
  }

  async componentDidMount() {
    try {
      const products = await Products.getProducts();

      this.setState({ products: products.data })
    } catch (error) {
      this.setState({error})
    }

  }

  renderBox(classNameMod) {
    return (
      <div
        className={cn({
          box: true,
          [classNameMod]: true
        })}
      >
        <div className={styles.boxWrapper}>
          <div className={styles.boxCaption}>Выбери лучшую технику<br/>для победы</div>
          <Button
            text="Узнать больше"
            slide="red"
            onClick={this.handleClick}
          />
        </div>
        <div className={styles.boxSquare}>
          <StripeGenerator
            space={12}
            className={styles.boxPattern}
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.error){
      throw this.state.error;
    }

    const { products } = this.state;

    return (
      <div className={styles.anchor} id="products-section">
        <DeviceContext.Consumer>
          {({ isMobile }) => (
            <div className={styles.section}>
              <ScrollOffsetBox offset={50} initialOffset={50} disable={isMobile}>
                <div className={styles.header}>
                  <h1 className={styles.title}>
                    Техника Omen
                  </h1>
                  { this.renderBox('box1') }
                </div>
              </ScrollOffsetBox>
              <div className={styles.content}>
                <ProductCarousel
                  items={products}
                />
              </div>
              { this.renderBox('box2') }
            </div>
          )}
        </DeviceContext.Consumer>
      </div>
    );
  }
}

export default SectionProducts;
