import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import styles from './TShirtFooter.module.scss';
// const sizes = ['xs', 's', 'm', 'l', 'xl'];
const cn = classNames.bind(styles);

class TShirtFooter extends Component {
  static propTypes = {
    amount: Type.number,
    maxAmount: Type.number,
    amountHandler: Type.func,
    productId: Type.number,
    size: Type.number,
  }

  handleUp = () => {
    const quantity = this.props.amount + 1;
    if (quantity > this.props.maxAmount) return;
    this.props.updateHandler({
      id: this.props.productId,
      quantity
    });
  }

  handleDown = () => {
    this.props.updateHandler({
      id: this.props.productId,
      quantity: this.props.amount - 1
    })
  }

  handleSize = (e) => {
    this.props.updateHandler({
      id: this.props.productId,
      size: Number(e.target.value),
      quantity: this.props.amount
    })
  }

  render() {
    const { amount, maxAmount, sizes } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.col}>
          <span className={styles.label}>Размер:</span>
          <div className={styles.sizes} >
            {sizes.map(({ size, id }, idx) => (
              <label
                key={idx}
                className={styles.sizeItem}
                title={`Выбрать размер ${size.toUpperCase()}`}>
                <input
                  type="radio"
                  name="size"
                  value={id}
                  checked={id === this.props.size}
                  onChange={this.handleSize}/>
                <span className={styles.sizeLabel}>{size}</span>
              </label>
            ))}
          </div>
        </div>
        <div className={cn({col: true, quantityCol: true})}>
          <span className={styles.label}>Количество:</span>
          <div className={styles.quantity}>
            <button
              title="Убрать 1"
              className={cn({ quantityBtn: true, quantityBtnMinus: true})}
              onClick={this.handleDown}
              disabled={amount === 1}></button>
            <input className={styles.input} readOnly value={String(amount)} type="text"/>
            <button
              title="Добавить еще 1"
              className={cn({ quantityBtn: true, quantityBtnPlus: true })}
              onClick={this.handleUp}
              disabled={amount === maxAmount}></button>
          </div>    
        </div>
      </div>
    );
  }
}
export default TShirtFooter;
