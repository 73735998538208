import React, { Component } from 'react'
import classNames from "classnames/bind";
import styles from "./DailyList.module.scss";
const cn = classNames.bind(styles);

export default class DailyList extends Component {
  renderItems = () => {
    const { list } = this.props;
    return list.map(n => {
      return (
        <div key={n.id} className={cn({
          item: true,
          disabled: n.joined_task
        })}>
          <div className={styles.icon}>
            <img src={n.icon} alt={n.name} />
          </div>
          <div className={styles.text}>{n.name}</div>
        </div>
      )
    })
  }

  render() {
    const { list } = this.props;
    return (
      <div className={styles.root}>
        { list.length > 0 
          ? this.renderItems()
          : <div className={ styles.text }>Список заданий пуст</div>
        }
      </div>
    )
  }
}
