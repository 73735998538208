import React, { Component } from 'react';
import Type from 'prop-types'
import ClassNames from 'classnames/bind';
import { Button, MenuAbilities, MobileMenuAbilities, StripeGenerator } from '../../../components';
import items from './items';
import { slides } from './mock-data';
import { dataLayers } from '../../../utils/gtm';
import { DeviceContext } from "../../../App";
import styles from './SectionHero.module.scss';

const cn = ClassNames.bind(styles);
class SectionHero extends Component {
  static propTypes = {
    slides: Type.arrayOf(Type.shape({
      description: Type.string,
    })),
    history: Type.shape({
      push: Type.func.isRequired,
    }),
    mode: Type.oneOf(['cs', 'dota', 'home'])
  };

  state = {
    currentMenuItem: 0,
  };

  handleSwitchSlide = (id) => {
    this.setState({ currentMenuItem: id });
  }

  handleClick = () => {
    window.dataLayer.push(dataLayers.wantToKnow);
    this.props.history.push('/mechanics');
  }

  handleNavClick = (to) => {
    this.props.history.push(to);
  }

  renderLabel() {
    const { mode } = this.props;
    if (mode === 'cs') return <div className={cn({ label: true })}><span>CS:GO</span></div>
    if (mode === 'dota') return <div className={cn({ label: true })}><span>DOTA2</span></div>
    return null
  }

  renderNav(isMobile) {
    const { mode } = this.props;
    if (['cs', 'dota'].includes(mode)) {

      if (isMobile) return <MobileMenuAbilities
        items={items}
        currentItem={this.state.currentMenuItem}
        onClick={this.handleSwitchSlide}
      />

      return <MenuAbilities
        items={items}
        currentItem={this.state.currentMenuItem}
        onClick={this.handleSwitchSlide}
      />
    }
    return null;
  }

  renderIndexBox() {
    return (
    <div className={styles.box}>
      <div className={styles.boxRow}>
        <div className={styles.boxTitle}>Это моя игра</div>
      </div>
      <div className={styles.boxRow}>
        <div className={styles.boxColumn}>
          <div className={styles.boxWrapper}>
            <Button
              text="CS:GO"
              borderColor="transparent"
              onClick={() => this.handleNavClick('/csgo')}
            />
          </div>
        </div>
        <div className={styles.boxColumn}>
          <div className={styles.boxWrapper}>
            <Button
              text="DOTA2"
              borderColor="transparent"
              onClick={() => this.handleNavClick('/dota2')}
            />
          </div>
        </div>
      </div>
    </div>)
  }

  renderBox() {
    return (<div className={styles.box}>
      <div className={styles.boxRow}>
        <div className={styles.boxColumn}>
          <div className={styles.boxTitle}>Играй лучше <br />чем вчера</div>
          <div className={styles.boxWrapper}>
            <Button
              text="Хочу знать как!"
              borderColor="transparent"
              onClick={this.handleClick}
            />
          </div>
        </div>
        <div className={styles.boxColumn} style={{ width: '90px' }}>
          <div className={styles.boxSquare}>
            <StripeGenerator
              space={12}
              strokeWidth={2}
              className={styles.boxPattern}
            />
          </div>
        </div>
      </div>
    </div>)
  }

  render() {
    const { currentMenuItem } = this.state;
    const { mode } = this.props;

    return (
      <DeviceContext.Consumer>
        {({ isMobile }) => (
          <div className={cn({ section: true, [mode]: true })} id="hero-section">
            <div className={styles.container}>
              <div className={styles.content}>
                <h1 className={styles.title}>
                  <span className={styles.titleSizer}>
                    {mode !== 'index' && this.renderLabel()}
                    omen2play
                  </span>
                </h1>

                <div className={cn({
                  bg: true,
                  [mode]: true
                })}></div>
                <div className={styles.fg}></div>

                {
                  mode === 'home' 
                  ? <>
                      <h3 className={styles.subtitle}>Играйте на новом уровне</h3>
                      <p className={styles.description}>
                        Добро пожаловать на&nbsp;OMEN2PLAY! Здесь ты&nbsp;можешь прокачивать скиллы, играя в&nbsp;Dota 2&nbsp;или CS:GO, выполнять ежедневные задания или практиковаться в&nbsp;мини-играх. Играй, зарабатывай очки и&nbsp;обменивай их&nbsp;на&nbsp;крутой мерч, поездки и&nbsp;билеты. Интересно? Тогда выбирай, с&nbsp;какой игры начать. Ты&nbsp;всегда можешь переключиться на&nbsp;другую.
                      </p>
                      {this.renderIndexBox()}
                    </>

                  : <>
                      <h3 className={styles.subtitle}>Играйте на новом уровне</h3>
                      <p className={styles.description}>
                        {slides[currentMenuItem].description}
                      </p>
                      {this.renderBox()}
                    </>
                }
              </div>
            </div>

            {this.renderNav(isMobile)}
          </div>
        )}
      </DeviceContext.Consumer>
    );
  }
}

export default SectionHero;
