import React, { Component } from 'react';
import Type from 'prop-types'
import { ReactComponent as Frame } from './frame.svg';
import styles from './ProductCard.module.scss';

class ProductCard extends Component {
  static propTypes = {
    item: Type.shape({
      title: Type.string,
      description: Type.string,
      url: Type.string,
      img: Type.any,
    }).isRequired,
    place: Type.number,
  };

  render() {
    const { item, place } = this.props;

    return (
      <a
        className={styles.card}
        href={item.url}
        target="_blank"
        rel='noreferrer noopener'
      >
        <Frame className={styles.frame} />

        <div className={styles.wrapper}>
          <div className={styles.cover}>
            <img
              src={item.img}
              alt={item.title}
              title={item.title}
              className={styles.image}
            />
          </div>
          <div className={styles.body}>
            <div className={styles.container}>
              <div className={styles.content}>
                <span className={styles.description}>
                  {item.description}
                </span>
                <span className={styles.title}>
                  {item.title}
                </span>
              </div>
              <div className={styles.extra}>
                <span className={styles.place}>{place}</span>
                <span className={styles.placeLabel}>место</span>
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  }
}

export default ProductCard;
