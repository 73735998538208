import React, { Component } from 'react';
import Type from 'prop-types'
import styles from './TourneyFooter.module.scss';
class TourneyFooter extends Component {
  static propTypes = {
    dates: Type.object,
  }

  handleChange = (e) => {
    const name = e.target.name;

    const payload = {
      id: this.props.productId,
      [name]: e.target.value
    }

    if (name === 'date') {
      payload.time = ''
    }

    this.props.updateHandler(payload)
  }

  render() {
    const { dates, selectedDate = "", selectedTime = "" } = this.props;

    const dateOptions = Object.keys(dates).map((date, idx) => {
      return <option key={idx} value={date}>{date}</option>
    });

    const timeOptions = (date) => {
      if (date) return dates[date].map((time, idx) => {
       return <option key={idx} value={time}>{time}</option>
      });
      return null
    }

    return (
      <div className={styles.root}>
        <div className={styles.label}>Дата и время поездки</div>
        <div className={styles.select}>
          <select name="date" value={selectedDate} onChange={this.handleChange}>
            <option value="" disabled>Выбрать дату</option>
            {dateOptions}
          </select>
        </div>
        <div className={styles.select}>
          <select name="time" value={selectedTime}  onChange={this.handleChange}>
            <option value="" disabled>Выбрать время</option>
            {timeOptions(selectedDate)}
          </select>
        </div>
      </div>
    );
  }
}
export default TourneyFooter;
