import React, { Component } from 'react';
import { ModalContext } from '../../App';

class ModalProvider extends Component {
  render (){
    return (
      <ModalContext.Provider value={this.props.value}>
        {this.props.children}
      </ModalContext.Provider>
    )
  }
}

export default ModalProvider;
