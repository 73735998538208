import React, {Component} from 'react';
import Type from 'prop-types'
import styles from "./Counter.module.scss";

class Counter extends Component {
  static propTypes = {
    currentPoints: Type.number,
    currentPercent: Type.number
  };

  state = {
    count: 0
  };

  calculateOlpCap = (currentPoints, currentPercent) => {
    return Math.ceil((currentPoints / 100) * currentPercent);
  };

  counter = () => {
    const {currentPoints, currentPercent} = this.props;
    const currentCap = this.calculateOlpCap(currentPoints, currentPercent);

    if (this.state.count > (currentCap - 112) && this.state.count < (currentCap + 112)) {
      this.setState({count: currentCap});
      return
    }

    if (this.state.count < currentCap) {
      this.setState((prevState) => (
        {
          count: prevState.count + 111
        }));

      this.animationCount = requestAnimationFrame(this.counter)
    } else if (this.state.count > currentCap) {
      this.setState((prevState) => (
        {
          count: prevState.count - 111
        }));

      this.animationCount = requestAnimationFrame(this.counter)
    } else {
      cancelAnimationFrame(this.animationCount);
    }
  };

  componentDidMount() {
    this.counter()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {currentPercent} = this.props;

    if (prevProps.currentPercent !== currentPercent) {
      this.counter()
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationCount);
  }

  render() {
    return (
      <div
        className={styles.points}
      >
        {this.state.count}
      </div>
    )
  }
}

export default Counter;
