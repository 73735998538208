import React, { Component } from 'react';
import Type from 'prop-types'
import ReactModal from 'react-modal';
import { StripeGenerator } from '../../components';
import styles from './SwitchDeviceModal.module.scss';

ReactModal.setAppElement('#root');

class SwitchDeviceModal extends Component {
  static propTypes = {
    isOpen: Type.bool,
    onClose: Type.func.isRequired,
  };

  state = {
    activeMenuItem: ''
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel="Смените устройство"
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
      >
        <div className={styles.container}>
          <div className={styles.body}>
            <div className={styles.title}>Не то<br/>устройство</div>
            <div className={styles.description}>Чтобы воспользоваться данной функцией, открой сайт на своем ПК!</div>
          </div>
          <div className={styles.footer}>
            <button
              className={styles.button}
              onClick={onClose}
            >
              Ok
            </button>
          </div>

          <StripeGenerator
            space={10}
            color="#ea002a"
            className={styles.stripe}
          />
        </div>
      </ReactModal>
    );
  }
}


export default SwitchDeviceModal;
