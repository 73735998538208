import cn from 'classnames';
import styles from './Spinner.module.scss';
import React from 'react';

export default function Spinner({ size = '24px', speed = 'normal', cName }) {
  return (
    <div className={cn(styles.root, cName)} style={{
      width: size,
      height: size
    }}>
      <div></div>
    </div>
  );
}

