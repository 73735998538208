import { request } from './instance';
const CHEKS_API_URL = 'https://checks.omen2play.ru/';
//payment duplicated

const Checks = {
  postCheck(data) {
    return request({
      url: '/',
      baseURL: CHEKS_API_URL,
      method: 'POST',
      canApplyToken: true,
      data,
    });
  },
  getChecks() {
    return request({
      url: '/userinfo',
      baseURL: CHEKS_API_URL,
      canApplyToken: true,
    });
  }
};

export default Checks;