import React, { Component } from 'react';
import { Button, Checkbox, Input, Select } from '../../components';
import { ReactComponent as IconArrow } from '../../assets/images/svg/arrow.svg';
import { dataLayers } from '../../utils/gtm';
import options from './mock-data';
import styles from './SignUp.module.scss';

class SignUp extends Component {
  state = {
    email: '',
    dota_role: '',
    personal_data: false,
    daily_mailing: false,
  };

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value
    });
  }

  handleSignUp = (event) => {
    event.preventDefault()
    window.dataLayer.push(dataLayers.confirmRegistration);
    this.props.handleSignUp(this.state);
  }

  render() {
    const { email, dota_role, personal_data, daily_mailing } = this.state;

    const enabled = email && dota_role && personal_data

    return (
      <>
        <div className={styles.fg}></div>

        <div className={styles.content}>
          <div className={styles.header}>
            <h1 className={styles.title}>Регистрация</h1>
            <div className={styles.stripe}></div>
          </div>
          <div className={styles.hint}>
            Заполни данные, чтобы принять участие в проекте.
          </div>

          <form>
            <div className={styles.field}>
              <Input
                type="email"
                name="email"
                autocomplete="off"
                placeholder="Email"
                value={email}
                onChange={this.handleChange}
                fullWidth
              />
            </div>

            <div className={styles.field}>
              <Select
                name="dota_role"
                placeholder="Выбери свою роль в игре"
                value={dota_role}
                options={options}
                onChange={this.handleChange}
                fullWidth
                isReadonly
              />
            </div>

            <div className={styles.field}>
              <Checkbox
                size="s"
                name="personal_data"
                text="Согласен на обработку персональных данных"
                checked={personal_data}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className={styles.field}>
              <Checkbox
                size="s"
                name="daily_mailing"
                text="Хочу получать напоминания о ежедневных заданиях"
                checked={daily_mailing}
                onChange={this.handleChange}
              />
            </div>

            <div className={styles.field}>
              <Button
                size="xs"
                type="submit"
                text="Зарегистрироваться"
                slide="red"
                icon={<IconArrow />}
                iconPosition="right"
                disabled={!enabled}
                onClick={(event) => this.handleSignUp(event)}
                dashed
                stripeProps={{
                  height: "12px",
                  top: "-12px"
                }}
              />
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default SignUp;
