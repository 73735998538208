import queryString from 'query-string';

const steamUrlToOpenId = (steamParameters) => {
  if (!steamParameters) {
    return
  }

  const openIdUrl =  queryString.parse(steamParameters)['openid.claimed_id']
  if (openIdUrl) {
    return openIdUrl.split('/').slice(-1)[0].trim()
  }
}

export { steamUrlToOpenId }
