import React, { Component } from 'react'
import FormControl from './FormControl';

export default class TourneyForm extends Component {
  render() {
    const { labelClass, rowClass, handleChange, userData, errors } = this.props;
    return (
      <div>
        <div className={rowClass}>
          <label className={labelClass}>Мобильный телефон для связи<span>*</span></label>
          <FormControl error={errors.phone}>
            <input type="tel" name="phone" value={userData.phone} onChange={handleChange}/>
          </FormControl>
        </div>
        <div className={rowClass}>
          <label className={labelClass}>Дополнительные комментарии к заказу</label>
          <FormControl error={errors.comment}>
            <textarea name="comment" rows="6" value={userData.comment} onChange={handleChange}></textarea>
          </FormControl>
        </div>
      </div>
    )
  }
}
