import { request } from './instance';

const TextBlocks = {
  getBlocks(params) {
    return request({
      url: 'text_blocks',
      params
    });
  },
};

export default TextBlocks;
