import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import styles from './SlideButton.module.scss';

const cn = classNames.bind(styles);

class SlideButton extends Component {
  static propTypes = {
    items: Type.arrayOf(Type.shape({
      text: Type.string.isRequired,
      counter: Type.number,
    })).isRequired,
    className: Type.string,
    size: Type.oneOf(['s', 'm', 'l']),
    withCounter: Type.bool,
    color: Type.string,
    disabled: Type.bool,
    onClick: Type.func,
  };

  static defaultProps = {
    size: 'm',
    withCounter: true,
  };

  state = {
    activeTab: 0,
  };

  getNode() {
    return this.control;
  }

  control;

  render() {
    const { activeTab } = this.state;

    const {
      items,
      withCounter,
      disabled,
      color,
      size,
      onClick,
      ...restProps
    } = this.props;

    const mods = {
      button: true,
      disabled: disabled,
      color: color,
      [`size_${size}`]: size,
    };

    const buttonProps = {
      ...restProps,
      ref: (control) => { this.control = control; },
      role: 'button',
      type: 'button',
      disabled,
    };

    return (
      <div className={styles.buttons}>
        { items.map((item, idx) =>
            <button
              key={idx}
              id={idx}
              {...buttonProps}
              className={cn({
                ...mods,
                active: activeTab === idx
              })}
            >
              <span className={styles.text}>{item.text}</span>
              { withCounter && <span className={styles.counter}>{item.counter}</span>}
            </button>
          )
        }
      </div>
    );
  }
}

export default SlideButton;
