import { ReactComponent as Icon1 } from '../../../assets/images/svg/figure-1.svg';
import { ReactComponent as Icon2 } from '../../../assets/images/svg/figure-2.svg';
import { ReactComponent as Icon3 } from '../../../assets/images/svg/figure-3.svg';
import Avatar1 from './avatar.jpg';
import Avatar2 from './avatar-2.jpg';
import Avatar3 from './avatar-3.jpg';
import Avatar4 from './avatar-4.jpg';
import Game1 from './game.png';
import Game2 from './game-2.png';
import Game3 from './game-3.png';

export const icons = [Icon1, Icon2, Icon3];

export const gameCards = [
  {
    title: 'Крюк',
    description: 'Поймай врага крюком 5 раз.',
    image: Game1,
  },
  {
    title: 'Купол',
    description: 'Поймай под куполом 9 врагов за 3 попытки.',
    image: Game2,
  },
  {
    title: 'Преграды',
    description: '3 раза приведи врага в нужную точку, преграждая ему путь.',
    image: Game3,
  }
];

export const teamCards = [
  {
    role: 'Керри',
    mmr: 1407,
    nickName: 'Dany_BOY_TH3_killa',
    avatar: Avatar1,
  },
  {
    role: 'Сапорт',
    mmr: 780,
    nickName: 'Dany_BOY_TH3_killa',
    avatar: Avatar2,
  },
  {
    role: 'Хардлайнер',
    mmr: 2014,
    nickName: 'Mishka1377',
    avatar: Avatar3,
  },
  {
    role: 'Миддер',
    mmr: 978,
    nickName: 'Dany_BOY_TH3_killa',
    avatar: Avatar4
  },
];
