import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import { SlideButton, TaskCard, StripeGenerator } from '../../../components';
import { AuthContext, DeviceContext } from "../../../App";
import ScrollOffsetBox from "../../ScrollOffsetBox/ScrollOffsetBox";
import styles from './SectionTasks.module.scss';

const cn = classNames.bind(styles);

class SectionTasks extends Component {
  static propTypes = {
    tasks: Type.arrayOf(Type.shape({
      id: Type.number,
      description: Type.string,
      image: Type.string,
      points_OLP: Type.number,
      joined_task: Type.number,
    })).isRequired,
    onClick: Type.func.isRequired,
  };

  handleClick(isAuthorized, handleOpen){
    if (!isAuthorized){
      handleOpen()
    }
  }

  renderDesktopTasks() {
    const { tasks, onClick } = this.props;

    return (
      <AuthContext.Consumer>
        {({ isAuthorized, handleOpen }) => (
          <div className={styles.section} id="tasks-section">
            <div className={styles.container}>
              <div className={styles.content}>
                <div className={styles.header}>
                  <div className={styles.head}>
                    <ScrollOffsetBox offset={100} initialOffset={100} types={['x']} reverse={false}>
                      <StripeGenerator
                        space={8}
                        color="#ea002a"
                        className={styles.stripe}
                      />
                    </ScrollOffsetBox>

                    <ScrollOffsetBox offset={100} initialOffset={100}>
                      <h2 className={styles.title}>Ежедневные задания</h2>
                    </ScrollOffsetBox>
                  </div>
                  {
                    (tasks && tasks.length > 0) 
                    && <div className={styles.slideButton} onClick={() => this.handleClick(isAuthorized, handleOpen)}>
                      <SlideButton
                        items={this.props.currentTasks}
                        withCounter
                      />
                    </div>
                  }
                </div>
                {
                  (tasks && tasks.length > 0) 
                    ? <div
                      className={cn({
                        cards: true,
                        isEmpty: tasks.length === 0,
                      })}
                    >
                      {tasks.map((task, idx) =>
                        <div className={styles.item} key={idx}>
                          <ScrollOffsetBox offset={100} initialOffset={idx * 50}>
                            <TaskCard
                              key={idx}
                              data={task}
                              onClick={onClick}
                            />
                          </ScrollOffsetBox>
                        </div>
                      )}
                    </div>
                    : <div className={styles.emptyBlock}>Новых заданий пока нет</div>
                }
              </div>
            </div>

            {tasks.length > 0 && <div className={styles.fg}></div>}      
          </div>
        )}
      </AuthContext.Consumer>
    );
  }

  renderMobileTasks(isMobile) {
    const { tasks, onClick } = this.props;

    return (
      <div className={styles.section} id="tasks-section">
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.head}>
                <StripeGenerator
                  space={8}
                  color="#ea002a"
                  className={styles.stripe}
                />

                <h2 className={styles.title}>Ежедневные задания</h2>
              </div>

              <div className={styles.slideButton} onClick={this.handleClick}>
                <SlideButton
                  items={this.props.currentTasks}
                  withCounter
                />
              </div>
            </div>
            <div
              className={cn({
                cards: true,
                isEmpty: tasks.length === 0,
              })}
            >
              {tasks.map((task, idx) =>
                <div className={styles.item} key={idx}>
                  <TaskCard
                    key={idx}
                    data={task}
                    isMobile={isMobile}
                    onClick={onClick}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {tasks.length > 0 && <div className={styles.fg}></div>}         
      </div>
    );
  }

  render() {
    return (
      <DeviceContext.Consumer>
        {({ isMobile }) => {
          return isMobile ?
            this.renderMobileTasks(isMobile) : this.renderDesktopTasks()
        }}
      </DeviceContext.Consumer>
    );
  }
}

export default SectionTasks;
