import React, { Component } from 'react';
import Type from 'prop-types'
import classNames from 'classnames/bind';
import { GameCard, TeamCard, StripeGenerator } from '../../../components';
import { ReactComponent as IconArrow } from '../../../assets/images/svg/arrow.svg';
import { gameCards, teamCards, icons } from './mock-data';
import { dataLayers } from '../../../utils/gtm';
import styles from './SectionGames.module.scss';
import { AuthContext, DeviceContext } from "../../../App";
import ScrollOffsetBox from "../../ScrollOffsetBox/ScrollOffsetBox";

const cn = classNames.bind(styles);

class SectionGames extends Component {
  static propTypes = {
    history: Type.shape({
      push: Type.func.isRequired,
    }),
  };

  state = {
    transform: 0,
    arrowTransform: -100,
    arrowRotate: 0,
    arrowAction: 'next',
    textTransform: 0
  };

  handlePlayGame = (idx, isAuthorized, handleOpen) => () => {
    // if (isAuthorized) {
      window.dataLayer.push(dataLayers.games[idx]);
      this.props.history.push('/game');
    // } else {
      // handleOpen()
    // }
  }

  handleArrowClick = () => {
    const {arrowAction} = this.state;

    arrowAction === 'next'
      ? this.setState({
        transform: -100,
        arrowTransform: -70,
        arrowRotate: 180,
        arrowAction: 'prev',
        textTransform: 65
      })
      : this.setState({
        transform: 0,
        arrowTransform: -100,
        arrowRotate: 0,
        arrowAction: 'next',
        textTransform: 0
      })
  }

  handleBuildOwnTeam = ( isAuthorized, handleOpen ) => {
    // if (isAuthorized) {
      window.dataLayer.push(dataLayers.team);

      this.props.history.push('/teams');
    // } else {
      // handleOpen()
    // }
  };

  renderSlide1(isAuthorized, isMobile, handleOpen) {
    return (
      <div key={'slide1'} className={cn({
        slide: true,
        slide_game: true,
      })}>
        <div className={styles.content}>
          <div className={styles.text} style={{transform:`translateX(calc(${this.state.textTransform}vw) `}}>
            <ScrollOffsetBox offset={100} initialOffset={30} disable={isMobile}>
              <h1 className={styles.title}>Практика, еще раз практика!</h1>
            </ScrollOffsetBox>

            <ScrollOffsetBox offset={100} initialOffset={80} disable={isMobile}>
              <p className={styles.description}>Отточи скиллы в мини-играх! Поймай врага крюком, поставь купол или правильно расставь преграды на пути соперника. Раз в сутки ты можешь получить OLP за успешное прохождение игр.</p>
            </ScrollOffsetBox>
          </div>

          <div className={styles.gameCards}>
            {gameCards.map((card, idx) =>
              <div key={idx} className={styles.gameCardItem}>
                <ScrollOffsetBox offset={80} initialOffset={idx * 50} types={['fade']} disable={isMobile}>
                  <GameCard
                    data={card}
                    icon={icons[idx]}
                    onClick={this.handlePlayGame(idx, isAuthorized, handleOpen)}
                    scrollBy={1}
                  />
                </ScrollOffsetBox>
              </div>
            )}
          </div>

          <div className={styles.bg}></div>
          <div className={styles.cross}></div>
        </div>
      </div>
    );
  }

  renderSlide2(isAuthorized, isMobile, handleOpen) {
    return (
      <div key={'slide2'} className={cn({
        slide: true,
        slide_team: true,
      })}>
        <div className={styles.content}>
          <div className={styles.text}>

            <ScrollOffsetBox offset={100} initialOffset={10} types={['x']} className={styles.buttonWrapper} disable={isMobile}>
              <button
                className={styles.button}
                onClick={() => this.handleBuildOwnTeam(isAuthorized, handleOpen)}
              >
                <span className={styles.buttonText}>Собрать свою</span>
                <span className={styles.buttonFingerPrint}>
                  <StripeGenerator
                    space={5}
                    color="#000"
                    className={styles.leftStripe}
                  />
                  <StripeGenerator
                    space={5}
                    color="#000"
                    className={styles.rightStripe}
                  />
                </span>

                <StripeGenerator
                  space={6}
                  color="#fff"
                  className={styles.bottomStripe}
                />
              </button>
            </ScrollOffsetBox>
          </div>

          <div className={styles.teamCards}>
            {teamCards.map((card, idx) =>
              <div key={idx} className={styles.teamCardItem}>
                <ScrollOffsetBox offset={80} initialOffset={idx * 50 - 50} disable={isMobile}>
                  <TeamCard
                    data={card}
                    backgroundColor="#ea002a"
                    customPointsColor="#000"
                  />
                </ScrollOffsetBox>
              </div>
            )}
          </div>

          <div className={styles.bg2}></div>
        </div>
      </div>
    );
  }

  render() {

    const { transform, arrowRotate, arrowTransform } = this.state;

    return (
      <DeviceContext.Consumer>
        {({ isMobile }) => (
          <AuthContext.Consumer>
            {({ isAuthorized, handleOpen }) => (
          <div className={styles.section} id="games-section">
            <StripeGenerator
              space={8}
              color="#000"
              className={styles.stripe}
            />
            <div className={styles.slides} style={{transform:`translateX(calc(${transform}vw)`}}>
              <div
                className={styles.nextSlide}
                onClick={this.handleArrowClick}
                style={{transform: `translateX(calc(${arrowTransform}vw)`}}
              >
                <span
                  className={styles.nextSlideButton}
                  onClick={this.handleArrowClick}
                >
                  <ScrollOffsetBox offset={100} initialOffset={10} types={['x', 'fade']} reverse={false}>
                    <IconArrow className={styles.arrow} style={{transform:`rotate(${arrowRotate}deg)`}}/>
                  </ScrollOffsetBox>
                </span>
                    <ScrollOffsetBox offset={100} initialOffset={10} types={['x', 'fade']} reverse={true}>
                      <h3 className={styles.slideTitle}>Собери свою команду</h3>
                      <p className={styles.slideDescription}>Кто ты? Танк, сапорт или DPS? Подбери себе команду по&nbsp;уровню и&nbsp;тренируйтесь вместе!</p>
                    </ScrollOffsetBox>
              </div>
              {[this.renderSlide1(isAuthorized, isMobile, handleOpen,), this.renderSlide2(isAuthorized, isMobile, handleOpen, isMobile) ]}
            </div>

          </div>
          )}
          </AuthContext.Consumer>
        )}
      </DeviceContext.Consumer>
    );
  }
}

export default SectionGames;
